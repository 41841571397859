import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import GoogleButton from 'react-google-button';
import { jwtDecode } from 'jwt-decode';
import axiosInstance from "../../interceptors/auth.interceptor.js";
import { Context } from '../../Context/Context.jsx';
import './login.module.css'
const Login = () => {
  const navigate = useNavigate();
  const { darkTheme} = useContext(Context);
  const redirectUri = 'https://tensorweave.com/auth/google/callback';

  const handleGoogleLogin = async () => {
    try {
      const response = await axiosInstance.get('/auth/login/google', {
        params: { redirect_uri: redirectUri },
      });
      window.location.href = response.data.authorization_url;
    } catch (error) {
      console.error('Error during Google login initiation:', error);
    }
  };

  useEffect(() => {
    const handleGoogleAuth = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      if (code) {
        try {
          const response = await axiosInstance.get('/auth/auth/google', {
            params: { code, redirect_uri: redirectUri },
          });
          localStorage.setItem('access_token', response.data.data.access_token);
          localStorage.setItem('user_email', jwtDecode(response.data.data.access_token).email);
          navigate('/home');
        } catch (error) {
          console.error('Error during Google authentication:', error);
        }
      }
    };

    handleGoogleAuth();
  }, [navigate, redirectUri]);

  return (
    <div className={`min-h-screen flex lora-font flex-col items-center justify-center transition-all duration-500 ${darkTheme ? "bg-[#1A1B1A]" : "bg-[#fafbfa]"}`}>
        <div className={`py-8 px-10 max-w-lg w-full ${darkTheme ? 'bg-[#212529] text-white' : 'bg-white text-gray-800'} rounded-lg shadow-lg`}>
          <h2 className="text-2xl font-bold mb-6 text-center">Connect with Google</h2>
          <GoogleButton onClick={handleGoogleLogin} style={{ width: '100%' }} />
        </div>
    </div>
  );
};

export default Login;
