import axiosInstance from '../interceptors/auth.interceptor.js';

export const createInsight = async (channel_id, source, url) => {
  try {
    const response = await axiosInstance.post(`/channel/${channel_id}/insight`, JSON.stringify({
      source,
      url
    }));
    return response.data;
  } catch (error) {
    console.error('Error creating insight:', error);
    return null;
  }
};
export const uploadPdf = async (channel_id, selectedFile) => {
  try {
    const formData = new FormData();
    formData.append("file", selectedFile);

    const response = await axiosInstance.post(`/channel/${channel_id}/fileUpload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error uploading PDF:", error.response || error);
    throw error; // Re-throw to be caught in `uploadPdfToBackend`
  }
};
export const getInsightDetails = async (channel_id, insight_id) => {
  try {
    const response = await axiosInstance.get(`/channel/${channel_id}/insight/${insight_id}/details`);
    return response.data;
  } catch (error) {
    console.error('Error getting insight details:', error);
    return null;
  }
};
export const deleteInsightDetails = async (channel_id, insight_id) => {
  try {
    await axiosInstance.delete(`/insight/${insight_id}`);
    return true;
  } catch (error) {
    console.error('Error getting insight details:', error);
    return false;
  }
};
export const updateInsightDetails = async (insight_id, updatedDetails) => {
  try {
    await axiosInstance.put(`/insight/${insight_id}`, updatedDetails);
    return true;
  } catch (error) {
    console.error('Error getting insight details:', error);
    return false;
  }
};


export const process_insight_data = (insight_data) => {
  return insight_data;
}