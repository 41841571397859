import React, { useContext } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import { Context } from "../Context/Context";
import SideNavigation from "../components/SideNavigation/SideNavigation";
const Layout = ({ children }) => {
    const { darkTheme } = useContext(Context);

    return (
        <div className={`flex h-screen ${darkTheme ? "bg-[#202020] text-text-dark" : "bg-[#F6F5F3] text-black"}`}>
            <div className="flex-1 flex flex-col scroll-container custom-scrollbar hide-scrollbar">
                {children}
            </div>
        </div>
    );
};

export default Layout;
