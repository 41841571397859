import { useContext, useState } from 'react';
import { Context } from '../../Context/Context';
import { deleteInsightDetails, getInsightDetails } from '../../services/insightService';
import DeleteModal from '../DeleteModal/DeleteModal';
import toast, { toastConfig } from 'react-simple-toasts';
import { useNavigate } from 'react-router-dom';
// Set toast configuration globally for consistent theme
toastConfig({ theme: 'warning' });

const DeleteInsightModal = () => {
    // Context values
    const { showDeleteInsightModal, setShowDeleteInsightModal, list, setList, setInsightDetails, darkTheme } = useContext(Context);
    
    // State for managing loading state during deletion
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    // Close the modal
    const handleClose = () => setShowDeleteInsightModal({ show: false, channel_id: null, workspace_id: null, insight_id: null });

    const handleDone = async () => {
        try {
            const { workspace_id, channel_id, insight_id } = showDeleteInsightModal;

            if (!workspace_id || !channel_id || !insight_id) {
                return;
            }

            // Set loading state to prevent multiple clicks
            setIsLoading(true);

            const response = await deleteInsightDetails(channel_id, insight_id);

            if (response) {
                const updatedList = list.filter(insight => insight.id !== insight_id);

                setList(updatedList);
                if (updatedList.length > 0) {
                    const newInsightId = updatedList[0].id;
                    const new_insight_response = await getInsightDetails(channel_id, newInsightId);
                    setInsightDetails(new_insight_response.data);
                    navigate(`/home/sp/${workspace_id}/ch/${channel_id}/in/${newInsightId}`, { replace: true });
                } else {
                    setInsightDetails(null);
                    navigate(`/home/sp/${workspace_id}/ch/${channel_id}`, { replace: true });
                }
                // Show success toast
                toast("✅ Insight deleted successfully!", {
                    position: 'top-center',
                    className: `${darkTheme ? "bg-black text-green-400" : "bg-white text-green-600"} py-3 px-4 rounded-lg shadow-lg flex items-center gap-2`,
                    style: {
                        border: "1px solid #4CAF50",
                        fontSize: "0.95rem",
                        fontWeight: "500",
                    },
                    clickClosable: true,
                    icon: '✅',
                });

            } else {
                throw new Error("Deletion failed");
            }
        } catch (error) {
            console.error("Error deleting insight:", error);

            toast("🚨 Error encountered while deleting insight. Please try again!", {
                position: 'top-center',
                className: `${darkTheme ? "bg-black text-red-400" : "bg-white text-red-600"} py-3 px-4 rounded-lg shadow-lg flex items-center gap-2`,
                style: {
                    border: "1px solid #ff4d4d",
                    fontSize: "0.95rem",
                    fontWeight: "500",
                },
                clickClosable: true,
                icon: '🚨',
            });
        } finally {
            handleClose();
            setIsLoading(false);
        }
    };

    return (
        <DeleteModal
            handleClose={handleClose}
            handleDone={handleDone}
            title="Delete Insight"
            description="Are you sure you want to delete this insight? This action cannot be undone."
            isOpen={showDeleteInsightModal.show}
            isLoading={isLoading}  // Pass the loading state to the modal component if needed
        />
    );
};

export default DeleteInsightModal;
