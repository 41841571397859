import React, { useContext, useState, useEffect, useRef, memo } from "react";
import { useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { motion } from 'framer-motion';
import { HiOutlineDotsVertical } from "react-icons/hi";
import { AiOutlineDelete } from "react-icons/ai";
import { RxCaretRight, RxCaretDown } from "react-icons/rx";
import { IoSearch, IoSparklesSharp } from "react-icons/io5";
import { HiHashtag } from "react-icons/hi";
import { GoPencil } from "react-icons/go";
import { Context } from '../../Context/Context.jsx';
import { icons } from "../icons.jsx";
import "./sidebar.css"
import { updateKnowledgeSpaceById } from "../../services/workspaceService.js";
import { getChannelDetails, getLatestActionsStatus, getLatestChannelData, updateChannelById } from "../../services/channelService.js"
import { getOrganizationDetails } from "../../services/orgService.js"
import { getInsightDetails } from "../../services/insightService.js";
import { getPromptStatus } from "../../services/promptServices.js";
import toast from 'react-simple-toasts';
import { FaPlus } from "react-icons/fa";
import { SlRefresh } from "react-icons/sl";
import { getThreadByChannelInsight } from "../../services/chatService.js";


const SidebarDropdown = memo(({
    children,
    triggerRef,
    isOpen,
    onClose,
    darkTheme,
    selectedChannel
}) => {
    const dropdownRef = useRef(null);
    const [position, setPosition] = useState({ top: -100, left: -100 });

    useEffect(() => {
        if (!isOpen) return;

        const calculatePosition = () => {
            const trigger = triggerRef?.current?.getBoundingClientRect();
            const dropdownWidth = 192;
            let left = trigger?.right + 15;
            let top = trigger?.top;

            if (left + dropdownWidth > window.innerWidth) {
                left = trigger.left - dropdownWidth;
            }
            if (top + dropdownRef.current?.offsetHeight > window.innerHeight) {
                top = window.innerHeight - dropdownRef?.current?.offsetHeight;
            }

            setPosition({ top: Math.max(0, top), left });
        };

        calculatePosition();

        const handleClickOutside = (event) => {
            if (
                dropdownRef?.current &&
                !dropdownRef.current?.contains(event.target) &&
                !triggerRef.current?.contains(event.target)
            ) {
                onClose();
            }
        };

        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        window.addEventListener('resize', calculatePosition);
        window.addEventListener('mousedown', handleClickOutside);
        window.addEventListener('keydown', handleEscapeKey);

        return () => {
            window.removeEventListener('resize', calculatePosition);
            window.removeEventListener('mousedown', handleClickOutside);
            window.removeEventListener('keydown', handleEscapeKey);
        };
    }, [isOpen, triggerRef]);

    if (!isOpen) return null;

    return (
        <div
            ref={dropdownRef}
            style={{
                position: 'fixed',
                top: `${position.top}px`,
                left: `${position.left}px`,
                zIndex: 50,
            }}
            className={`w-48 border rounded-md transition-opacity duration-300
                ${darkTheme
                    ? "bg-[#2d2d2e] border-[#273037] text-white shadow-[0_4px_10px_rgba(150,150,150,0.15)]"
                    : "bg-white border-[#868686] text-black shadow-lg"}`}
        >
            {children}
        </div>
    );
});


const Sidebar = ({ spaceId, channelId, insightId }) => {
    const { knowledgespace, setKnowledgespace, channelData, setShowSpaceModal, setInsightDetails, setSummaryInfo, setOrgData, pathIndex, setShowDeleteChannelModal, setShowSmartSpaceModal, setShowDeleteSpaceModal, setShowChannelModal, setPathIndex, darkTheme, setList, setLoadingInsightsList, setLoadingInsight, open, setOpen, setActiveSection, setChannelData, setIsPromptLoading, isPromptLoading, userData, setAllThreadData, setMessages } = useContext(Context);
    const { setShowDashboard, orgData } = useContext(Context);
    const [editingIndex, setEditingIndex] = useState(null);
    const [editingChannelIndex, setEditingChannelIndex] = useState(null);
    const [newName, setNewName] = useState("");
    const [newChannelName, setNewChannelName] = useState("");
    const [dropdownIndex, setDropdownIndex] = useState(null);
    const [channelDropdownIndex, setChannelDropdownIndex] = useState({ workspace: null, channel: null });
    const [visibleChannels, setVisibleChannels] = useState({});
    const [loadingRefreshButton, setLoadingRefreshButton] = useState(null);  // For icon rotation and button state
    const [refreshButtonDisabled, setRefreshButtonDisabled] = useState(false); // Button disable state
    const [pollingLatest, setPollingLatest] = useState(false);  // Polling state
    const [selectedChannelId, setSelectedChannelId] = useState(null);
    const [hoveredItem, setHoveredItem] = useState(null);
    const [hoveredChannel, setHoveredChannel] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredSpace, setFilteredSpace] = useState(knowledgespace);
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [hoveredMenuItem, setHoveredMenuItem] = useState(null);

    const inputRef = useRef(null);
    const timeoutRef = useRef(null);
    const spaceThreeDotsRef = useRef(null);
    const channelThreeDotsRef = useRef(null);
    const dropdownRef = useRef(null);
    const channelDropdownRef = useRef(null);
    const bottomRef = useRef(null);
    const navigate = useNavigate();
    const menuItems = [
        { id: 'Smart Research', icon: IoSparklesSharp, label: 'Smart Research' },
        { id: 'Add Workspace', icon: FaPlus, label: 'Add Workspace' },
    ];


    useEffect(() => {
        let poller;
    
        const pollStatus = async () => {
            try {
                const response = await getPromptStatus(isPromptLoading.prompt_id);
    
                if (response?.data.run_status === "success") {
                    clearInterval(poller);
                    poller = null;
    
                    const org_id = userData.org_roles?.[0]?.org_id;
                    const org_data_temp = await getOrganizationDetails(org_id);
                    if (!org_data_temp?.data) return;
    
                    const org_data = org_data_temp.data;
                    setOrgData(org_data);
                    
                    const knowledge_space_data = org_data.spaces?.map((space) => ({
                        id: space.space_id,
                        name: space.name,
                        icon: space.icon || "🌏",
                        channels: space.channels.map((channel) => ({
                            id: channel.channel_id,
                            name: channel.name,
                            icon: <HiHashtag />,
                            path: ["Knowledge Space >>", space.name, channel.name],
                            unread_insights_count: channel.unread_insights_count,
                        })),
                        path: ["Knowledge Spaces", space.name],
                    }));
                    
                    setKnowledgespace(knowledge_space_data);
                    console.log("Updated Knowledge Space Data:", knowledge_space_data);
                    
                    // Locate the new space and channel
                    const newSpace = knowledge_space_data.find(
                        (space) => space.name === isPromptLoading.space_name
                    );
                    console.log("space name:", newSpace.name);
                    
                    if (newSpace) {
                        const newChannel = newSpace.channels.find(
                            (channel) => channel.name === isPromptLoading.channel_name
                        );
                        console.log("New Channel:", newChannel);
                        
                        if (newChannel) {
                            toast('✅ Insights loaded successfully!', {
                                position: 'top-center',
                                className: `${darkTheme ? "bg-black text-green-400" : "bg-white text-green-600"} py-3 px-4 rounded-lg flex items-center gap-2`,
                                duration: 2000,
                            });
                            console.log("Navigating to:", `/home/sp/${newSpace.id}/ch/${newChannel.id}`);
                            await handlePathClick(newSpace.id, newChannel.id);
                            setSelectedChannelId(newChannel.id);
                        } else {
                            console.error("New Channel not found");
                        }
                        setLoadingInsightsList(false);
                        setLoadingInsight(false);
                        setIsPromptLoading({
                            loading: false,
                            space_id: null,
                            prompt_id: null,
                            space_name: null,
                            channel_name: null,
                        });
                    } else {
                        console.error("New Space not found");
                    }
                } else if (response?.data.run_status === "failed") {
                    clearInterval(poller);
                    poller = null;
    
                    setLoadingInsightsList(false);
                    setLoadingInsight(false);
    
                    setIsPromptLoading({
                        loading: false,
                        space_id: null,
                        prompt_id: null,
                        space_name: null,
                        channel_name: null,
                    });
    
                    toast('❌ Loading failed. Please try again.', {
                        position: 'top-center',
                        className: `${darkTheme ? "bg-black text-red-400" : "bg-white text-red-600"} py-3 px-4 rounded-lg flex items-center gap-2`,
                        duration: 2000,
                    });
                }
            } catch (error) {
                console.error('Error while polling status:', error);
                clearInterval(poller);
            }
        };
    
        if (isPromptLoading?.loading === true) {
            scrollToBottom();
            setLoadingInsightsList(true);
            setLoadingInsight(true);
    
            poller = setInterval(pollStatus, 5000);
        }
    
        return () => {
            clearInterval(poller);
        };
    }, [isPromptLoading]);

    const handleClickAddWorkspace = async () => {
        setShowSpaceModal(true);

        // setTimeout(() => {
        //     setIsPromptLoading({ loading: false, space_id: null, prompt_id : null});
        // }, 10000);
        // Check if loading is true to start polling
    };
    const handleClickAddSmartSpace = async () => {
        setShowSmartSpaceModal(true);

        // setTimeout(() => {
        //     setIsPromptLoading({ loading: false, space_id: null, prompt_id : null});
        // }, 10000);
        // Check if loading is true to start polling
    };
    const handleClickAddChannel = (space_id) => {
        setShowChannelModal({ show: true, id: space_id });
    };

    const handleRenameWorkspace = (id, name) => {
        setEditingIndex(id);
        setNewName(name);
        setDropdownIndex(null);
    };
    const handleRenameChannel = (workspaceIndex, channelIndex, channelName) => {
        setChannelDropdownIndex({ workspace: null, channel: null });
        setEditingChannelIndex({ workspaceIndex: workspaceIndex, channelIndex: channelIndex });
        setNewChannelName(channelName);
    }
    const scrollToBottom = () => {
        if (bottomRef?.current) {
            bottomRef.current.scrollTop = bottomRef.current.scrollHeight;
        }
    };
    const scrollToTop = () => {
        if (bottomRef?.current) {
            bottomRef.current.scrollBottom = bottomRef.current.scrollHeight;
        }
    };
    const handleNameChange = (e) => {
        setNewName(e.target.value.trimStart());
    };

    const handleChannelNameChange = (e) => {
        setNewChannelName(e.target.value.trimStart());
    };

    const handleNameSubmit = async (id) => {
        try {
            if (newName.trim().length < 1) {
                toast("🚨 Error: space name cannot be empty.", {
                    position: 'top-center',
                    className: `${darkTheme ? "bg-black text-red-400" : "bg-white text-red-600"} py-3 px-4 rounded-lg shadow-lg flex items-center gap-2`,
                    style: {
                        border: "1px solid #ff4d4d",
                        fontSize: "0.95rem",
                        fontWeight: "500",
                    },
                    clickClosable: true,
                    duration: 3000,
                    icon: '🚨',
                });

                setEditingIndex(null);
                setNewName("");
                return;
            }
            const isDuplicate = filteredSpace.some(space => space.name.toLowerCase() === newName.toLowerCase());
            if (isDuplicate) {
                toast("🚨 Error: space with the same name already exists.", {
                    position: 'top-center',
                    className: `${darkTheme ? "bg-black text-red-400" : "bg-white text-red-600"} py-3 px-4 rounded-lg shadow-lg flex items-center gap-2`,
                    style: {
                        border: "1px solid #ff4d4d",
                        fontSize: "0.95rem",
                        fontWeight: "500",
                    },
                    clickClosable: true,
                    duration: 3000,
                    icon: '🚨',
                });

                setEditingIndex(null);
                setNewName("");
                return;
            }

            const updatedData = {
                name: newName,
            };
            await updateKnowledgeSpaceById(updatedData, id);
            setKnowledgespace((prevData) => {
                return prevData.map((item) =>
                    item.id === id ? { ...item, name: newName, path: ["Knowledge Spaces", newName] } : item
                );
            });
            setEditingIndex(null);
            setNewName("");
        } catch (error) {
            console.log(error);
        }
    };
    const fetchThreadData = async (channel_id, insight_id) => {
        try {
            if (channel_id && insight_id) {
                const thread_data = await getThreadByChannelInsight(channel_id, insight_id);
                if (thread_data?.data.length > 0) {
                    setAllThreadData(thread_data?.data);
                    const initialMessages = thread_data.data[0].conversations.flatMap(conv => [
                        { text: conv.question, sender: 'user' },
                        { text: conv.answer, sender: 'bot' }
                    ]);
                    setMessages(initialMessages);
                }
                else {
                    setAllThreadData(thread_data?.data);
                    setMessages([]);
                }
            }
            else {
                setMessages([]);
            }
        } catch (error) {
            console.error("Error fetching thread data:", error);
        }
    };

    const handleChannelNameSubmit = async (workspaceid, channelid) => {
        try {
            if (newChannelName.length < 1) {
                toast("🚨 Error: channel name cannot be empty.", {
                    position: 'top-center',
                    className: `${darkTheme ? "bg-black text-red-400" : "bg-white text-red-600"} py-3 px-4 rounded-lg shadow-lg flex items-center gap-2`,
                    style: {
                        border: "1px solid #ff4d4d",
                        fontSize: "0.95rem",
                        fontWeight: "500",
                    },
                    clickClosable: true,
                    duration: 3000,
                    icon: '🚨',
                });
                setEditingChannelIndex(null);
                setNewChannelName("");
                return;
            }
            const isDuplicateName = filteredSpace.some(space =>
                space.channels.some(channel =>
                    channel.name.toLowerCase() === newChannelName.toLowerCase()
                )
            );

            if (isDuplicateName) {
                toast("🚨 Error: channel with the same name already exists.", {
                    position: 'top-center',
                    className: `${darkTheme ? "bg-black text-red-400" : "bg-white text-red-600"} py-3 px-4 rounded-lg shadow-lg flex items-center gap-2`,
                    style: {
                        border: "1px solid #ff4d4d",
                        fontSize: "0.95rem",
                        fontWeight: "500",
                    },
                    clickClosable: true,
                    duration: 3000,
                    icon: '🚨',
                });
                setEditingChannelIndex(null);
                setNewChannelName("");
                return;
            }

            const updatedChannelData = {
                name: newChannelName,
            };
            await updateChannelById(updatedChannelData, channelid);
            setKnowledgespace((prevData) => {
                return prevData.map((workspace) => {
                    if (workspace.id === workspaceid) {
                        return {
                            ...workspace,
                            channels: workspace.channels.map((channel) =>
                                channel.id === channelid ? { ...channel, name: newChannelName } : channel
                            ),
                        };
                    }
                    return workspace;
                });
            });

            setEditingChannelIndex(null);
            setNewChannelName("");
        } catch (error) {
            console.log(error);
        }
    };


    const handleKeyPress = (e, id) => {
        if (e.key === "Enter") {
            handleNameSubmit(id);
        }
    };

    const handleChannelKeyPress = (e, workspaceIndex, channelIndex) => {
        if (e.key === "Enter") {
            handleChannelNameSubmit(workspaceIndex, channelIndex);
        }
    };

    const handlePathClick = async (workspace_id, channel_id) => {
        try {
            if (!workspace_id || !channel_id) return;
            setLoadingInsightsList(true);
            setLoadingInsight(true);
            setShowDashboard(false);

            setSelectedChannelId(channel_id);

            const response = await getChannelDetails(channel_id);
            const channel_data = response?.data;
            setChannelData(channel_data);
            if (!channel_data) {
                setLoadingInsightsList(false);
                setLoadingInsight(false);
                return;
            }

            channel_data?.insights?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            const insights = channel_data.insights.map(insight_data => ({
                id: insight_data.insight_id,
                logo: insight_data.source_data.logo,
                title: insight_data.source_data.title,
                image: insight_data.source_data.top_image,
                description: insight_data.description,
                read: insight_data.read,
                source: insight_data.source,
                url: insight_data.url,
                time: insight_data.created_at,
                icon: icons.network,
            }));

            setList(insights);
            setActiveSection(2);
            if (insights.length > 0) {
                const firstInsight = insights[0];
                setPathIndex(prevPathIndex => ({
                    ...prevPathIndex,
                    workspace_id: workspace_id,
                    channel_id: channel_id,
                    insight_id: firstInsight.id,
                }));
                const response_insights = await getInsightDetails(channel_id, firstInsight.id);
                if (response_insights && response_insights.data) {
                    setInsightDetails(response_insights.data);
                    setSummaryInfo(response_insights.data);
                    navigate(`/home/sp/${workspace_id}/ch/${channel_id}/in/${firstInsight.id}`, { replace: true });
                    await fetchThreadData(channel_id, firstInsight.id);
                }
            } else {
                setPathIndex(prevPathIndex => ({
                    ...prevPathIndex,
                    workspace_id: workspace_id,
                    channel_id: channel_id,
                    insight_id: null,
                }));
                setInsightDetails(null);
                navigate(`/home/sp/${workspace_id}/ch/${channel_id}`, { replace: true });
                await fetchThreadData(channel_id, null);
            }
            setLoadingInsightsList(false);
            setLoadingInsight(false);
        } catch (error) {
            console.error('Error in handlePathClick:', error);
            setLoadingInsightsList(false);
            setLoadingInsight(false);
        }
    };
    const handleDropdownToggle = (id, event) => {
        event.stopPropagation();
        if (dropdownIndex === id) {
            setDropdownIndex(null);
        } else {
            setDropdownIndex(id);
        }
    };
    const handleChannelDropdownToggle = (workspaceId, channelId) => {
        if (channelDropdownIndex.workspace === workspaceId && channelDropdownIndex.channel === channelId) {
            setChannelDropdownIndex({ workspace: null, channel: null });
        } else {
            setChannelDropdownIndex({ workspace: workspaceId, channel: channelId });
        }
    };
    const handleDeleteWorkspace = (space_id) => {
        setShowDeleteSpaceModal({ show: true, id: space_id });
    };

    const handleDeleteChannel = (workspace_id, channel_id) => {
        setShowDeleteChannelModal({ show: true, channel_id: channel_id, workspace_id: workspace_id });
    }
    const handleClickSearch = () => {
        setIsSearchActive(true);
        inputRef.current?.focus();
    };

    const handleBlur = () => {
        if (!searchQuery) {
            timeoutRef.current = setTimeout(() => setIsSearchActive(false), 200);
        }
    };

    const handleFocus = () => {
        clearTimeout(timeoutRef.current);
    };

    const handleClickOutside = (event) => {
        if (
            dropdownRef.current &&
            spaceThreeDotsRef.current === null &&
            !dropdownRef.current.contains(event.target) &&
            !event.target.closest('.dropdown-trigger') &&
            !spaceThreeDotsRef.current?.contains(event.target)
        ) {
            setDropdownIndex(null);
        }
        if (
            channelDropdownRef.current &&
            !channelDropdownRef.current.contains(event.target) &&
            !event.target.closest('.channel-dropdown-trigger') &&
            !channelThreeDotsRef.current?.contains(event.target)
        ) {
            setChannelDropdownIndex({ workspace: null, channel: null });
        }
    };
    const highlightText = (text) => {
        if (!searchQuery) return text;

        const regex = new RegExp(`(${searchQuery})`, 'gi');
        return text.split(regex).map((part, index) =>
            regex.test(part) ? (
                <span key={index} className="bg-yellow-400">{part}</span>
            ) : (
                part
            )
        );
    };

    const toggleChannelsVisibility = (index) => {
        setVisibleChannels((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };


    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    const handleClickWorkspace = (index) => {
        toggleChannelsVisibility(index);
    };

    const mapOrganizationDetails = async () => {
        try {
            if (orgData && orgData.spaces && orgData.spaces.length > 0 && orgData.spaces[0].channels && orgData.spaces[0].channels.length > 0) {
                if (spaceId && channelId && insightId) {
                    setPathIndex(prevPathIndex => ({
                        ...prevPathIndex,
                        workspace_id: spaceId,
                        channel_id: channelId,
                        insight_id: insightId
                    }));
                }
            } else {
                console.log("Organization data is not fully loaded or is empty");
            }
        } catch (error) {
            console.error("Error in mapOrganizationDetails:", error);
        }
    };

    const mapChannelInsightDetails = async () => {
        try {
            await mapOrganizationDetails();
            if (orgData && orgData.spaces && orgData.spaces.length > 0 && orgData.spaces[0].channels && orgData.spaces[0].channels.length > 0) {
                if (spaceId && channelId && insightId) {
                    setPathIndex(prevPathIndex => ({
                        ...prevPathIndex,
                        workspace_id: spaceId,
                        channel_id: channelId,
                        insight_id: insightId,
                    }));
                }
            } else {
                console.log("Channel data is not fully loaded or is empty");
            }
        } catch (error) {
            console.error("Error in mapChannelInsightDetails:", error);
        }
    }
    const pollStatus = async (space_id, channel_id, action_id) => {
        try {
            const response = await getLatestActionsStatus(channel_id, action_id);
            if (response && response?.data.run_status === "success") {
                setPollingLatest(false);
                setLoadingRefreshButton(null);
                setRefreshButtonDisabled(false);
                handlePathClick(space_id, channel_id);
                toast('✅ Channel refreshed successfully!!!',
                    {
                        position: 'top-center',
                        className: `${darkTheme ? "bg-black text-green-400" : "bg-white text-green-600"} py-3 px-4 rounded-lg flex items-center gap-2`,
                        style: {
                            border: "1px solid #4CAF50",
                            fontSize: "0.95rem",
                            fontWeight: "500",
                        },
                        duration: 2000,
                        theme: 'success',
                    });
            } else {
                // Continue polling if not yet "success"
                setTimeout(() => pollStatus(space_id, channel_id, action_id), 5000);
            }
        } catch (error) {
            console.error("Error fetching channel status:", error);
            setPollingLatest(false);
            setLoadingRefreshButton(null);
            setRefreshButtonDisabled(false);
        }
    };

    const handleRefreshClick = async (space_id, channel_id) => {
        if (loadingRefreshButton?.channel_id === channel_id) return;
        setLoadingRefreshButton({ loading: true, channel_id: channel_id });
        setRefreshButtonDisabled(true);
        handlePathClick(space_id, channel_id);
        try {
            const response = await getLatestChannelData(channel_id);
            if (response && response?.data.action_id) {
                toast("refreshing channel...", {
                    position: 'top-center',
                    theme: 'info',
                    className: `${darkTheme ? "bg-black text-white" : "bg-white text-black"} py-3 px-4 rounded-lg flex items-center gap-2`,
                    loading: setLoadingRefreshButton !== null ? true : false,
                    duration: 5000,
                    clickClosable: true
                });
                pollStatus(space_id, channel_id, response?.data.action_id);
            }
        } catch (error) {
            console.error("Error initiating channel refresh:", error);
            setLoadingRefreshButton(null);
            setRefreshButtonDisabled(false);
        }
    };
    useEffect(() => {
        let filtered = knowledgespace || [];
        scrollToTop();
        if (searchQuery) {
            filtered = filtered.filter(space => {
                // Check if the space name includes the search query
                const spaceMatches = space.name.toLowerCase().includes(searchQuery.toLowerCase());
                // Check if any channel name includes the search query
                const channelMatches = space.channels.some(channel =>
                    channel.name.toLowerCase().includes(searchQuery.toLowerCase())
                );

                // Return true if either space or any channel matches
                return spaceMatches || channelMatches;
            });
        }

        setFilteredSpace(filtered);
    }, [searchQuery, knowledgespace]);

    useEffect(() => {
        if (filteredSpace.length > 0 && open) {
            const initialVisibility = {};
            filteredSpace.forEach((item) => {
                initialVisibility[item.id] = true;
            });
            setVisibleChannels(initialVisibility);
        }
    }, [filteredSpace, open]);

    useEffect(() => {
        if (orgData) {
            mapChannelInsightDetails();
        }
        setSelectedChannelId(channelData?.channel_id);
    }, [orgData]);

    return (
        <>
            <aside className={`h-screen fixed top-[5%] sm:top-[41px] border sm:left-[50px] 3xl:left-[50px] z-[10] ${darkTheme ? "bg-[#181818] border-[#ffffff16]" : "bg-[#fafbfa]"} overflow-x-hidden hide-scrollbar w-[95%] sm:w-[185px] md:w-[195px] lg:w-56 3xl:w-64 xl:w-56 px-2`}
                ref={bottomRef}
            >
                <div className={`fixed w-[90%] sm:w-190px lg:w-[221px] 3xl:w-[254px] ml-[-8px] z-[6] border-b ${darkTheme ? "bg-[#181818] border-[#ffffff16]" : "bg-[#fafbfa] border-[#3d3d3d2d]"}`}>
                    <div className={`flex flex-row items-center justify-center w-[104%] ${open ? "my-[2px]" : ""} justify-between border-b ${darkTheme ? "border-[#ffffff16]" : "border-[#3d3d3d2d]"} pb-[1px] ml-[-4px]`}>
                        {open && <div className={`${darkTheme ? "text-white" : "text-[#000000] font-semibold"} p-2 sm:mx-2`}>Workspaces</div>}
                        <div className={`flex flex-row mt-[1px]`}>
                        </div>
                    </div>
                    <div className="pt-2"></div>
                    <center className={`p-[3px] ml-[2px] ${darkTheme ? "hover:text-white hover:bg-[#f15025]" : "hover:bg-[#f15025] hover:text-white"}  rounded-lg`}>
                        <span
                            onClick={handleClickAddSmartSpace}
                            className={`cursor-pointer text-[15px] w-full ease-in-out`}
                        >
                            <div className="p-1 flex flex-row space-x-4"><IoSparklesSharp className="mt-1 ml-[6px]" /><p className="ml-[-2px]">Smart Research</p></div>
                        </span>
                    </center>
                    <center className={`p-[3px] ml-[2px] ${darkTheme ? "hover:!text-white hover:bg-[#f15025]" : "hover:bg-[#f15025] hover:text-white"}  rounded-lg`}>
                        <span
                            onClick={handleClickAddWorkspace}
                            className={`cursor-pointer text-[15px] w-full ease-in-out r`}
                        >
                            <div className="p-1 flex flex-row space-x-4"><PlusOutlined className=" ml-1" /><p>Add New</p></div>
                        </span>
                    </center>
                    <center className={`p-[3px] ml-[2px] ${isSearchActive ? "hover:!bg-inherit hover:!text-black" : ""} ${darkTheme ? "hover:!text-white hover:bg-[#f15025]" : "hover:bg-[#f15025] hover:text-white"} rounded-lg`}>
                        {!isSearchActive && (
                            <span
                                onClick={handleClickSearch}
                                className="cursor-pointer text-[15px] w-full ease-in-out"
                            >
                                <div className="p-1 flex flex-row space-x-4">
                                    <IoSearch className="mt-1 ml-1" />
                                    <p>Browse</p>
                                </div>
                            </span>
                        )}

                        {isSearchActive && (
                            <div
                                onClick={handleClickSearch}
                                className="cursor-pointer flex flex-row items-center text-[15px] w-full transition-all duration-300 ease-in-out"
                            >
                                <IoSearch className="ml-2" />
                                <input
                                    type="text"
                                    placeholder="Browse Workspaces"
                                    value={searchQuery}
                                    onChange={(e) => {
                                        setSearchQuery(e.target.value);

                                    }}
                                    className={`ml-2 px-2 py-1 rounded-md outline-none transition-all duration-300 ease-in-out w-[80%] ${darkTheme ? "bg-[#2d2d2e] text-white" : "bg-[#e8e8e9] text-black"}`}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    autoFocus
                                />
                            </div>
                        )}
                    </center>
                    <div className="pb-2"></div>
                </div>

                <div className="h-auto flex flex-col mt-[172px] ml-[-10px] sm:ml-[-15px] mb-4">
                    <div className={`${open ? " w-[100%]" : "md:w-14 3xl:w-16"} mb-16`}>
                        <nav className="w-full">
                            <ul className="capitalize text-sm">
                                {filteredSpace && filteredSpace.map((item, index) => (
                                    <li key={index} className="relative cursor-pointer group">
                                        <div
                                            className={`flex items-center mt-1 rounded-md ml-2 ${open ? "px-3 py-1 sm:py-0.5 gap-2" : "ml-1 mr-1 px-0 py-1 justify-center"}
                                            ${darkTheme ? "text-white hover:bg-[#2d2d2e]" : "text-black hover:bg-[#e8e8e9]"} ${editingIndex === item.id ? "" : "hover:bg-none"}
                                            transition-all duration-300 ease-in-out ${isPromptLoading?.space_id === item.id ? "opacity-10 animate-pulse" : ""}`}
                                            onMouseEnter={() => setHoveredItem(item.id)}
                                            onMouseLeave={() => setHoveredItem(null)}
                                            onClick={() => {
                                                if (isPromptLoading?.space_id === item.id) return;
                                                setOpen(true);
                                            }}
                                        >
                                            <button
                                                className={`text-[14px] ${darkTheme ? "" : "font-semibold text-black"}`}
                                                onClick={() => {
                                                    if (isPromptLoading?.space_id === item.id) return;
                                                    handleClickWorkspace(item.id)
                                                }
                                                }
                                            >
                                                {visibleChannels[item.id] ? <RxCaretDown className={`text-[22px] ${darkTheme ? "text-white" : "text-[#2d2d2e]"}`} /> : <RxCaretRight className={`text-[22px] ${darkTheme ? "text-white" : "text-[#2d2d2e]"}`} />}
                                            </button>

                                            {open && (
                                                <div className="flex items-center space-x-2 w-full text-[18px] sm:text-[14px]">
                                                    <span
                                                        onClick={() => {
                                                            if (isPromptLoading?.space_id === item.id) return;
                                                            handleClickWorkspace(item.id);
                                                        }}
                                                        className={`cursor-pointer ${darkTheme ? "text-white" : "font-semibold text-black"} w-full transition-all duration-300 ease-in-out`}
                                                    >
                                                        {editingIndex === item.id ? (
                                                            <input
                                                                type="text"
                                                                value={newName}
                                                                onChange={handleNameChange}
                                                                onKeyDown={(e) => handleKeyPress(e, item.id)}
                                                                onBlur={() => setTimeout(() => handleNameSubmit(item.id), 100)}
                                                                autoFocus
                                                                className={`bg-transparent border-none rounded-md p-1 m-0 w-full transition-all duration-300 ease-in-out                                                                            bg-transparent border-none p-0 m-0 focus:outline-none ${darkTheme
                                                                    ? 'placeholder-gray-400 border-[#3d4144] focus:shadow-[0_0_2px_1px_rgba(59,140,246,0.75),0_0_1px_1px_rgba(59,130,246,0.5),0_0_1px_1px_rgba(59,130,246,0.25)]'
                                                                    : 'placeholder-[#acacad] border-[#c7c7c7] focus:shadow-[0_0_2px_1px_rgba(59,130,246,0.75),0_0_1px_1px_rgba(59,130,246,0.5),0_0_1px_1px_rgba(59,130,246,0.25)]'}`}
                                                            />
                                                        ) : (
                                                            <div className="sm:truncate sm:w-[120px] p-1">{highlightText(item.name)}</div>
                                                        )}
                                                    </span>
                                                    {open && !editingIndex && hoveredItem === item.id && (
                                                        <div className="relative flex ml-auto space-x-2 items-center" ref={spaceThreeDotsRef}>
                                                            <button
                                                                onClick={() => {
                                                                    if (isPromptLoading?.space_id === item.id) return;
                                                                    handleClickAddChannel(item.id);
                                                                }}
                                                                className={`font-semibold rounded-md ${darkTheme ? "text-[#868686] hover:bg-[#273037]" : "text-[#273037] hover:bg-[#a9a9a9]"}`}
                                                            >
                                                                <PlusOutlined />
                                                            </button>
                                                            <button
                                                                className={`text-[14px] p-1 font-semibold dropdown-trigger rounded-md ${darkTheme ? "text-[#868686] hover:bg-[#273037]" : "text-[#273037] hover:bg-[#a9a9a9]"}`}
                                                                onClick={(event) => {
                                                                    if (isPromptLoading?.space_id === item.id) return;
                                                                    handleDropdownToggle(item.id, event);
                                                                }}
                                                            >
                                                                <HiOutlineDotsVertical />
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        <SidebarDropdown
                                            isOpen={dropdownIndex === item.id}
                                            triggerRef={spaceThreeDotsRef}
                                            onClose={() => setDropdownIndex(null)}
                                            onBlur={() => setDropdownIndex(null)}
                                            darkTheme={darkTheme}
                                        >
                                            <ul>
                                                <li
                                                    onClick={() => handleRenameWorkspace(item.id, item.name)}
                                                    onBlur={() => setDropdownIndex(null)}
                                                    className={`cursor-pointer p-2 flex items-center ${darkTheme ? "hover:text-white" : "text-[#3d4144] hover:text-black"
                                                        }`}
                                                >
                                                    <GoPencil className="mr-2" />
                                                    Edit
                                                </li>
                                                <li
                                                    onClick={() => handleDeleteWorkspace(item.id)}
                                                    onBlur={() => setDropdownIndex(null)}
                                                    className={`cursor-pointer p-2 flex items-center ${darkTheme ? "hover:text-white" : "text-[#3d4144] hover:text-black"
                                                        }`}
                                                >
                                                    <AiOutlineDelete className="mr-2" />
                                                    Delete
                                                </li>
                                            </ul>
                                        </SidebarDropdown>
                                        {visibleChannels[item.id] && (
                                            <div className="ml-[30px]">
                                                {item.channels && item.channels.map((channel, idx) => (
                                                    <div
                                                        key={idx}
                                                        className={`flex items-center justify-between px-3 py-[6px] sm:py-1 mt-1 rounded-md
                                                                ${selectedChannelId === channel.id ? (darkTheme ? "bg-[#2d2d2e] text-white border border-blue-600" : "bg-[#e8e8e9] border border-blue-600") : ""}
                                                                ${channel.unread_insights_count > 0 ? `font-bold ${darkTheme ? "text-white" : "text-black"}` : ""}
                                                                ${darkTheme ? "hover:bg-[#2d2d2e]" : "hover:bg-[#e8e8e9]"}
                                                                transition-all duration-300 ease-in-out
                                                                ${channel.id === "prompt_channel" ? "opacity-10 animate-pulse" : ""}
                                                            `}
                                                        onMouseEnter={() => setHoveredChannel(channel.id)}
                                                        onMouseLeave={() => setHoveredChannel(null)}
                                                        onClick={() => {
                                                            if (channel.id === "prompt_channel") return;
                                                            handlePathClick(item.id, channel.id);
                                                        }}
                                                    >
                                                        <div className="flex items-center space-x-2 cursor-pointer">
                                                            <div className="my-auto">#</div>
                                                            <div className="text-[18px] sm:text-[14px] sm:truncate sm:w-[120px]">
                                                                {editingChannelIndex &&
                                                                    editingChannelIndex.workspaceIndex === item.id &&
                                                                    editingChannelIndex.channelIndex === channel.id ? (
                                                                    <input
                                                                        type="text"
                                                                        value={newChannelName}
                                                                        onChange={handleChannelNameChange}
                                                                        onKeyDown={(e) => handleChannelKeyPress(e, item.id, channel.id)}
                                                                        onBlur={() => setTimeout(() => handleChannelNameSubmit(item.id, channel.id), 100)}
                                                                        autoFocus
                                                                        className={`bg-transparent border-none p-0 m-0 focus:outline-none ${darkTheme
                                                                            ? "placeholder-gray-400 border-[#3d4144] focus:shadow-[0_0_3px_1px_rgba(59,140,246,0.75),0_0_1px_1px_rgba(59,130,246,0.5),0_0_1px_1px_rgba(59,130,246,0.25)]"
                                                                            : "placeholder-[#acacad] border-[#c7c7c7] focus:shadow-[0_0_3px_1px_rgba(59,130,246,0.75),0_0_1px_1px_rgba(59,130,246,0.5),0_0_1px_1px_rgba(59,130,246,0.25)]"
                                                                            }`}
                                                                    />
                                                                ) : (
                                                                    highlightText(channel.name)
                                                                )}
                                                            </div>
                                                        </div>

                                                        {!editingChannelIndex && (hoveredChannel === channel.id || selectedChannelId === channel.id) ? (
                                                            <div className="absolute right-0 flex flex-row">
                                                                <button
                                                                    className={`mr-1 text-[17px] sm:text-[14px] ${loadingRefreshButton && loadingRefreshButton?.channel_id === channel.id ? "animate-spin" : ""
                                                                        } ${darkTheme ? "text-white hover:bg-[#273037] rounded-md" : "text-black hover:bg-[#a9a9a9] rounded-md"}`}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        handleRefreshClick(item.id, channel.id);
                                                                    }}
                                                                    disabled={refreshButtonDisabled}
                                                                >
                                                                    <SlRefresh />
                                                                </button>

                                                                <button
                                                                    className={` ${channel.unread_insights_count > 0 ? "mr-[30px]" : "mr-[5px]"} text-[17px] sm:text-[14px] ${hoveredChannel === channel.id || selectedChannelId === channel.id ? "py-1" : "p-1"
                                                                        } ${darkTheme ? "text-white hover:bg-[#273037] rounded-md" : "text-black hover:bg-[#a9a9a9] rounded-md"}`}
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        handleChannelDropdownToggle(item.id, channel.id, event);
                                                                    }}
                                                                    ref={channelThreeDotsRef}
                                                                >
                                                                    <HiOutlineDotsVertical />
                                                                </button>
                                                                <div
                                                                    className={`absolute right-2 w-4 h-4 3xl:w-5 mt-[3px] 3xl:h-5 flex items-center justify-center rounded-full bg-orange-600 text-white sm:text-[9px] 3xl:text-[11px] font-mono ${channel.unread_insights_count <= 0 ? "hidden" : "block"
                                                                        }`}
                                                                >
                                                                    {channel.unread_insights_count}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className={`absolute right-2 w-4 h-4 3xl:w-5 3xl:h-5 flex items-center justify-center rounded-full bg-orange-600 text-white sm:text-[9px] 3xl:text-[11px] font-mono ${channel.unread_insights_count <= 0 ? "hidden" : "block"
                                                                    }`}
                                                            >
                                                                {channel.unread_insights_count}
                                                            </div>
                                                        )}

                                                        <SidebarDropdown
                                                            isOpen={
                                                                channelDropdownIndex?.workspace === item.id &&
                                                                channelDropdownIndex?.channel === channel.id
                                                            }
                                                            triggerRef={channelThreeDotsRef}
                                                            onClose={() => setChannelDropdownIndex({ workspace: null, channel: null })}
                                                            onBlur={() => setChannelDropdownIndex({ workspace: null, channel: null })}
                                                            darkTheme={darkTheme}
                                                            className={`z-50`}
                                                            selectedChannel={channel.id === selectedChannelId}
                                                        >
                                                            <button
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    handleRenameChannel(item.id, channel.id, channel.name);
                                                                }}
                                                                className={`w-full cursor-pointer p-2 flex items-center ${darkTheme ? "hover:text-white" : "text-[#3d4144] hover:text-black"
                                                                    }`}
                                                            >
                                                                <GoPencil className="mr-2" />
                                                                Edit
                                                            </button>
                                                            <button
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    handleDeleteChannel(item.id, channel.id);
                                                                }}
                                                                className={`w-full cursor-pointer p-2 flex items-center ${darkTheme ? "hover:text-white" : "text-[#3d4144] hover:text-black"
                                                                    }`}
                                                            >
                                                                <AiOutlineDelete className="mr-2" />
                                                                Delete
                                                            </button>
                                                        </SidebarDropdown>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </nav>

                    </div>
                </div>
                {/* {open && <div className={`xxs:hidden sm:block ${darkTheme ? "bg-[#181818]" : "bg-[#fafbfa]"}  ${open ? "sm:fixed sm:w-190px lg:w-[215px] 3xl:w-[235px] bottom-[0px] px-6 py-3 mt-4 pr-2 pl-1 " : "w-full absolute bottom-[0px] left-[-3px] pr-0 pl-[7px]"}`}>
                    {!isSearchActive && (
                        <IoSearch
                            onClick={handleClickSearch}
                            className={`cursor-pointer sm:ml-40 3xl:ml-48 transition-all duration-500 ease-in-out ${darkTheme ? "text-white" : "text-black"}`}
                        />
                    )}
                    {isSearchActive && (
                        <input
                            type="text"
                            placeholder="Search Spaces"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className={`pl-8 pr-4 py-1 rounded-md outline-none transition-all duration-500 ease-in-out ${darkTheme ? "bg-[#2d2d2e] text-white" : "bg-[#e8e8e9] text-black"}`}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            autoFocus
                            style={{ width: isSearchActive ? '190px' : '0px' }}
                        />
                    )}
                    {isSearchActive && (
                        <IoSearch className={`absolute left-2 top-1/2 transform -translate-y-1/2 ${darkTheme ? "text-white" : "text-black"}`} />
                    )}
                </div>} */}
            </aside >
        </>
    );
};

export default Sidebar;
