import React, { useState, useEffect, useRef, useContext } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Context } from "../../Context/Context";
import { Breadcrumb, Spin, message } from "antd";
import axios from "axios";
import { motion } from "framer-motion";
import { ProgressBar } from "@react-pdf-viewer/core";
import { FaCircleXmark } from "react-icons/fa6";

const PdfViewerPage = () => {
  const {
    darkTheme,
    isPdfAvailable,
    setIsPdfAvailable,
    knowledgespace,
    pathIndex,
    actionState,
    insightDetails,
    setActionState,
  } = useContext(Context);
  const [fileUrl, setFileUrl] = useState(null); // Store the object URL
  const viewerContainerRef = useRef(null);
  const [path, setPath] = useState([]);
  const [loading, setLoading] = useState(true); // Loader state

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  // const bookmarkPluginInstance = bookmarkPlugin();

  useEffect(() => {
    const updatePath = () => {
      let newPath = [];
      if (pathIndex?.workspace_id !== null) {
        const workspace = knowledgespace.find(
          (ws) => ws.id === pathIndex.workspace_id
        );
        if (workspace) {
          newPath.push(workspace.name);
          if (pathIndex?.channel_id !== null) {
            const channel = workspace.channels.find(
              (ch) => ch.id === pathIndex?.channel_id
            );
            if (channel) {
              newPath.push(channel.name);
            }
          }
        }
      }
      if (newPath.length > 0) setPath(newPath);
    };

    const fetchPdf = async () => {
      setLoading(true); // Start loader
      try {
        const rawUrl = insightDetails?.url;
        if (!rawUrl) return;

        const url = new URL(rawUrl);
        const cleanUrl = url.origin + url.pathname;

        const response = await axios.get(cleanUrl, {
          responseType: "blob",
        });

        const blob = new Blob([response.data], { type: "application/pdf" });
        const objectUrl = URL.createObjectURL(blob);

        setFileUrl(objectUrl);
      } catch (error) {
        console.error("Error fetching the PDF file:", error);
        message.error("Failed to load the PDF file.");
      } finally {
        setLoading(false); // Stop loader whether success or error
      }
    };

    updatePath();
    fetchPdf();
    return () => {
      if (fileUrl) {
        URL.revokeObjectURL(fileUrl);
      }
    };
  }, [pathIndex, insightDetails]);

  return (
    <div
      className={`min-h-screen w-full mx-auto border-x z-[10] ${
        darkTheme ? "bg-[#181818] border-[#ffffff16]" : "bg-[#fafbfa]"
      }`}
      ref={viewerContainerRef}
    >
      <div
        className={` border-b ${
          darkTheme
            ? "bg-[#181818] border-[#ffffff16]"
            : "bg-[#fafbfa] border-[#00000016]"
        } `}
      >
        <div
          className={`flex flex-row justify-between items-center p-[1px] ${
            darkTheme ? "border-[#ffffff16]" : "border-[#00000016]"
          }`}
        >
          <div
            className={`pl-2 cursor-pointer ${
              actionState === null ? "hidden" : ""
            }`}
            onClick={() => {
              setActionState(null);
              setIsPdfAvailable(false);
            }}
          >
            <FaCircleXmark
              className={`w-5 h-5 rounded-full ${
                darkTheme ? "bg-black text-white" : "text-black bg-white"
              }`}
            />
          </div>

          <div className="p-2 mx-2 mt-1 min-h-[40px] flex-grow">
            <Breadcrumb
              separator={
                <span
                  className={`${darkTheme ? "text-white" : "text-[#2f2f2f]"}`}
                >
                  {">>"}
                </span>
              }
              items={path.map((p, index) => ({
                title: (
                  <span
                    className={`${
                      darkTheme ? "text-white" : "text-[#2f2f2f]"
                    } font-semibold`}
                  >
                    {p.split(" ")[0].charAt(0).toUpperCase() +
                      p.split(" ")[0].slice(1)}
                  </span>
                ),
                key: index,
              }))}
            />
          </div>
          <motion.div
            className={`flex py-[2px] px-[2px] mt-[1px] rounded-md overflow-hidden shadow-sm ${
              actionState === null || insightDetails.source !== "pdf"
                ? "hidden"
                : ""
            } ${darkTheme ? "bg-[#303030] text-white" : "bg-gray-200"}`}
            initial={{ opacity: 0, scale: 1 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
          >
            <button
              onClick={() => setIsPdfAvailable(true)}
              className={`relative w-16 py-[3px] px-2 font-semibold text-[12px] tracking-wider transition-all duration-300 ease-in-out rounded-md ${
                isPdfAvailable ? "bg-white text-black" : ""
              }`}
            >
              PDF
            </button>
            <button
              onClick={() => setIsPdfAvailable(false)}
              className={`relative w-16 py-[3px] px-2 font-semibold text-[12px] tracking-wider transition-all duration-300 ease-in-out rounded-md ${
                !isPdfAvailable ? "bg-white text-gray-700" : ""
              }`}
            >
              Insight
            </button>
          </motion.div>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-[90vh]">
          <Spin size="large" tip="Loading PDF..." />
        </div>
      ) : fileUrl ? (
        <div
          className="h-[90vh] w-full border rounded-lg shadow-lg overflow-hidden z-[9]"
          style={{
            background: "#f5f5f5",
          }}
        >
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
            <Viewer
              fileUrl={fileUrl} // Use the object URL
              theme="dark"
              defaultScale="PageFit"
              withCredentials={false}
              plugins={[defaultLayoutPluginInstance]}
              renderLoader={(percentages) => (
                <div style={{ width: "240px" }}>
                  <ProgressBar progress={Math.round(percentages)} />
                </div>
              )}
            />
          </Worker>
        </div>
      ) : (
        <div className="text-gray-500 p-2">Failed to load the PDF file.</div>
      )}
    </div>
  );
};

export default PdfViewerPage;
