import React, { useContext, useRef, useState } from "react";
import { motion } from "framer-motion";
import { Context } from "../../Context/Context.jsx";
import { RiCloseFill } from "react-icons/ri";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
} from "@headlessui/react";
import {
  createInsight,
  getInsightDetails,
  uploadPdf,
} from "../../services/insightService.js";
import { GoPaperclip } from "react-icons/go";
import { icons } from "../icons.jsx";
import { Upload, Button } from "antd";
import { ImSpinner2 } from "react-icons/im";
import toast, { toastConfig } from "react-simple-toasts";
import { HiArrowRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import { getThreadByChannelInsight } from "../../services/chatService.js";
const { Dragger } = Upload;
toastConfig({ theme: "warning" });

const AddInsightModal = () => {
  const {
    showIssueModal,
    setShowIssueModal,
    pathIndex,
    setInsightDetails,
    setList,
    darkTheme,
    setPathIndex,
    setAllThreadData,
    setMessages,
  } = useContext(Context);
  const [uploadedUrl, setUploadedUrl] = useState("");
  const [selectedFile, setSelectedFile] = useState(null); // Local file
  const [pdfUrl, setPdfUrl] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pdfUploaded, setPdfUploaded] = useState(false);
  const [showLabel, setShowLabel] = useState(false);
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({}); // Error state

  const handleClose = () => {
    setShowIssueModal(false);
    setSelectedFile(null);
    setUploadedFiles([]);
    setPdfUrl("");
    setErrors({});
    setPdfUploaded(false);
    setUploading(false);
    setLoading(false);
    setUploadedUrl("");
  };

  const handleFileChange = (file) => {
    setSelectedFile(file);
    setErrors({});
  };

  // const handleUpload = async () => {
  //   if (!selectedFile) {
  //     setErrors({ uploadedUrl: "Please select a file first!" });
  //     return;
  //   }

  //   if (selectedFile.size > 10 * 1024 * 1024) {
  //     setErrors({ uploadedUrl: "File size should not exceed 10MB." });
  //     return;
  //   }
  //   setUploading(true);
  //   try {
  //     const response = await uploadPdf(pathIndex.channel_id, selectedFile);
  //     if (response.data.file_url) {
  //       setPdfUrl(response.data.file_url);
  //       setUploadedFiles([selectedFile]);
  //       setPdfUploaded(true);
  //       toast("✅ File uploaded successfully!", { theme: "success" });
  //     } else {
  //       throw new Error("Upload failed");
  //     }
  //   } catch (error) {
  //     console.error("Upload Error:", error);
  //     toast("🚨 File upload failed!", { theme: "error" });
  //     setErrors((prev) => ({ ...prev, uploadedUrl: "File upload failed!" }));
  //   } finally {
  //     setUploading(false);
  //   }
  // };

  function checkSource(url) {
    const check =
      url.includes("youtube") ||
      url.includes("youtu") ||
      url.includes("youtu.be");
    return check ? "youtube" : "web";
  }

  const handleDone = async () => {
    const newErrors = {};
    if (!uploadedUrl.trim() && !selectedFile) {
      setErrors({ uploadedUrl: "Please provide a URL or upload a file." });
      return;
    }

    if (selectedFile && selectedFile.size > 10 * 1024 * 1024) {
      setErrors({ uploadedUrl: "File size should not exceed 10MB." });
      return;
    }

    setLoading(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    handleClose();

    let taskToast = null;

    const taskPromise = new Promise(async (resolve, reject) => {
      try {
        let finalUrl = uploadedUrl;

        // Show "Uploading Document..." toast
        taskToast = toast(`Uploading Document...`, {
          position: "top-center",
          theme: "info",
          className: `${darkTheme ? "bg-black text-white" : "bg-white text-black" 
            } py-3 px-4 rounded-lg flex items-center gap-2 border border-[#f15025]`,
          duration: Infinity,
          loading: true
        });

        if (selectedFile) {
          const uploadResponse = await uploadPdf(pathIndex.channel_id, selectedFile);
          if (uploadResponse.data.file_url) {
            finalUrl = uploadResponse.data.file_url;
          } else {
            throw new Error("File upload failed!");
          }
        }

        // Update toast to "Creating Insight..."
        taskToast.update({
          message: "Creating Insight...",
          theme: "info",
          duration: Infinity,
        });

        const sourceType = selectedFile ? "pdf" : checkSource(finalUrl);

        const response = await createInsight(
          pathIndex.channel_id,
          sourceType,
          finalUrl
        );
        if (!response || response.data.success === false)
          throw new Error("Failed to create insight.");

        const insight_data = await getInsightDetails(
          response.data.channels[0],
          response.data.insight_id
        );
        if (!insight_data || insight_data.data.success === false)
          throw new Error("Failed to fetch insight details.");

        if (insight_data?.data) {
          setInsightDetails(insight_data?.data);
        }

        setList((prevData) => [
          {
            id: insight_data?.data.insight_id,
            logo: insight_data?.data.source_data.logo,
            title: insight_data?.data.source_data.title,
            image: insight_data?.data.source_data.top_image,
            description:
              insight_data?.data.summary.analysis.insights[0].points[0] ||
              "No Description Available",
            source: insight_data?.data.source,
            url: insight_data?.data.url,
            time: new Date().toISOString(),
            icon: icons.network,
          },
          ...prevData,
        ]);

        if (insight_data?.data?.insight_id) {
          setPathIndex((prevIndex) => ({
            ...prevIndex,
            insight_id: insight_data?.data.insight_id,
          }));
        }
        navigate(
          `/home/sp/${pathIndex.space_id}/ch/${pathIndex.channel_id}/in/${insight_data?.data.insight_id}`,
          { replace: true }
        );

        resolve();
        let thread_data;
        if (pathIndex.channel_id && insight_data?.data.insight_id) {
          thread_data = await getThreadByChannelInsight(
            pathIndex.channel_id,
            insight_data?.data.insight_id
          );
        }
        if (thread_data?.data) {
          setAllThreadData(thread_data.data);
          const conversation_array = thread_data.data.map((item) => ({
            thread_id: item.thread_id,
            insight_id: item.insight_id,
            conversations: item.conversations,
          }));

          if (conversation_array && insight_data?.data.insight_id) {
            const selectedThread = conversation_array.find(
              (item) => item.insight_id === insight_data?.data.insight_id
            );
            if (selectedThread && selectedThread?.conversations?.length) {
              const initialMessages = selectedThread.conversations.flatMap(
                (conv) => [
                  { text: conv.question, sender: "user" },
                  { text: conv.answer, sender: "bot" },
                ]
              );
              setMessages(initialMessages);
            } else {
              setMessages([]);
            }
          }
        }
      } catch (error) {
        console.error("Error creating insight:", error.message || error);
        reject(new Error("Error creating insights. Please try again!"));
      }
    });

    // Show toast with loading state
    taskPromise
      .then(() =>
        taskToast.update({
          message: "✅ Insight Created Successfully!",
          position: "top-center",
          className: `${darkTheme ? "bg-black text-green-400" : "bg-white text-green-600"
            } py-3 px-4 rounded-lg flex items-center gap-2 border border-[#f15025]`,
          style: {
            fontSize: "0.95rem",
            fontWeight: "500",
          },
          duration: 2000,
          theme: "success",
          loading: false
        })
      )
      .catch(() =>
        taskToast.update({
          message:
            "🚨 Error encountered while creating insights. Please try again!",
          position: "top-center",
          className: `${darkTheme ? "bg-black text-red-400" : "bg-white text-red-600"
            } py-3 px-4 rounded-lg flex items-center gap-2 border border-[#f15025]`,
          style: {
            fontSize: "0.95rem",
            fontWeight: "500",
          },
          duration: 2000,
          icon: "🚨",
        })
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const isValidURL = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };
  const handleFileDrop = (files) => {
    if (files.length) {
      const file = files[0];
      if (file.type === "application/pdf" && file.size <= 10 * 1024 * 1024) {
        beforeUpload(file);
      } else {
        setErrors((prev) => ({
          ...prev,
          uploadedUrl: "Only PDF files are allowed and must be under 10MB.",
        }));
      }
    }
  };

  const beforeUpload = async (file) => {
    setUploadedFiles([file]);
    setSelectedFile(file);
  };
  const onKeyEnter = (event) => {
    if (event.key === "Enter") {
      if (loading) {
        return;
      }
      handleDone();
    }
  };

  return (
    <Transition appear show={showIssueModal} as={React.Fragment}>
      <Dialog
        as="div"
        className="relative z-[999]"
        onClose={handleClose}
        initialFocus={inputRef}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={`fixed inset-0 ${darkTheme ? "bg-black" : "bg-white"
              } bg-opacity-65 backdrop-blur-md`}
            aria-hidden="true"
          />
        </Transition.Child>

        <div className="fixed inset-0 z-[999] overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-visible rounded-xl shadow-none transition-all sm:max-w-3xl w-full ${darkTheme ? "text-white" : "text-black"
                  }`}
              >
                <p
                  className={`text-[30px] py-4 ${darkTheme ? "text-gray-300" : ""
                    }`}
                >
                  Get detailed insights from any website or a document
                </p>
                <div
                  className={`items-center rounded-xl w-[100%] border border-[#f15025] ${darkTheme ? "bg-[#000]" : "bg-white"
                    }`}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={(e) => {
                    e.preventDefault();
                    handleFileDrop(e.dataTransfer.files);
                  }}
                >
                  <div className="px-6 py-2">
                    {errors.uploadedUrl && (
                      <p className="text-left text-sm text-red-500 px-4">
                        {errors.uploadedUrl}
                      </p>
                    )}
                    <div className="flex items-center gap-2">
                      <input
                        ref={inputRef}
                        id="url"
                        type="url"
                        placeholder="Paste a URL or upload a document"
                        className={`w-full px-3 py-2 my-1 bg-transparent border-none outline-none resize-none text-base ${darkTheme
                          ? "text-zinc-200 placeholder-zinc-500"
                          : "text-zinc-800 placeholder-zinc-400"
                          }`}
                        value={uploadedUrl}
                        readOnly={pdfUploaded}
                        onKeyDown={(e) => onKeyEnter(e)}
                        onChange={(e) => {
                          setUploadedUrl(e.target.value);
                          setErrors((prev) => ({
                            ...prev,
                            uploadedUrl: undefined,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className={`px-6 py-1 flex justify-between sm:px-6 border-t ${darkTheme ? "border-gray-800" : ""
                      }`}
                  >
                    <div className="flex items-center gap-4">
                      {showLabel && (
                        <div
                          className={`absolute left-2 bottom-8 transform -translate-y-1/2 mr-2 px-2 py-1 text-[12px] text-white rounded whitespace-nowrap ${darkTheme ? "bg-[#808080]" : "bg-[#808080]"
                            }`}
                        >
                          Add Files
                        </div>
                      )}

                      <Upload
                        name="file"
                        multiple={false}
                        beforeUpload={beforeUpload}
                        accept=".pdf"
                        showUploadList={false}
                      >
                        <motion.div
                          className={`cursor-pointer text-[18px] ${darkTheme ? "text-white" : ""
                            }`}
                          onHoverStart={() => {
                            setShowLabel(true);
                          }}
                          onHoverEnd={() => {
                            setShowLabel(false);
                          }}
                          disabled={uploading || pdfUrl}
                        >
                          <GoPaperclip />
                        </motion.div>
                      </Upload>
                      {uploadedFiles.length > 0 && (
                        <p
                          className={`text-sm ${darkTheme ? "text-gray-300" : "text-gray-700"
                            }`}
                        >
                          {uploadedFiles[0]?.name || "No file selected"}
                        </p>
                      )}
                      {!selectedFile && (
                        <p className="text-left text-[12px] text-gray-500">
                          (Only PDF files are allowed. Max size: 10MB, Max pages: 30.)
                        </p>
                      )}
                      {/* {uploadedFiles.length > 0 && !pdfUrl && (
                        <div className="flex items-center gap-2">
                          {uploading ? (
                            <ImSpinner2 className="animate-spin text-[#f15025]" />
                          ) : (
                            <p
                              onClick={handleUpload}
                              className={`rounded-lg text-[#f15025] hover:text-orange-700 ${uploading ? "cursor-not-allowed" : ""
                                }`}
                              disabled={uploading}
                            >
                              Upload PDF
                            </p>
                          )}
                        </div>
                      )} */}
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-2"></div>
                      <button
                        onClick={() => {
                          if (loading) return;
                          handleDone();
                        }}
                        className={`sm:p-2 3xll:p-4 rounded-full ${uploadedUrl?.length > 0 || selectedFile
                          ? "text-[#f15025] hover:text-[#f15025]"
                          : "text-[#404040]"
                          }`}
                        disabled={!selectedFile && uploadedUrl?.length === 0}
                      >
                        <motion.div>
                          <HiArrowRight className="h-5 w-5" />{" "}
                        </motion.div>
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default AddInsightModal;
