import { useContext, useState } from 'react';
import { Context } from '../../Context/Context';
import { deleteChannelById } from '../../services/channelService';
import DeleteModal from '../DeleteModal/DeleteModal';
import toast, { toastConfig } from 'react-simple-toasts';
import { deleteChannelJob } from '../../services/schedulerJobService';

// Set toast configuration globally for consistent theme
toastConfig({ theme: 'warning' });

const DeleteJobModal = ({ showDeleteJobModal, setShowDeleteJobModal, setJobs }) => {
    const { darkTheme } = useContext(Context);
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => setShowDeleteJobModal({ show: false, job_id: null });

    const handleDone = async () => {
        try {
            const { job_id } = showDeleteJobModal;

            // If no job ID is available, exit
            if (!job_id) return;

            // Start loading
            setIsLoading(true);

            // Call delete job API
            const response = await deleteChannelJob(job_id);

            // Update the jobs state by removing the deleted job
            setJobs(prevJobs =>
                prevJobs.filter(job => job.job_id !== job_id)
            );

            // Close the modal
            handleClose();

            // Show success toast
            toast("✅ Job deleted successfully!", {
                position: 'top-center',
                className: `${darkTheme ? "bg-black text-green-400" : "bg-white text-green-600"} py-3 px-4 rounded-lg shadow-lg flex items-center gap-2`,
                style: {
                    border: "1px solid #4CAF50",
                    fontSize: "0.95rem",
                    fontWeight: "500",
                },
                clickClosable: true,
                icon: '✅',
            });
        } catch (error) {
            // Catch and show any errors encountered during deletion
            console.error(error);
            toast("🚨 Error encountered while deleting job. Please try again!", {
                position: 'top-center',
                className: `${darkTheme ? "bg-black text-red-400" : "bg-white text-red-600"} py-3 px-4 rounded-lg shadow-lg flex items-center gap-2`,
                style: {
                    border: "1px solid #ff4d4d",
                    fontSize: "0.95rem",
                    fontWeight: "500",
                },
                clickClosable: true,
                icon: '🚨',
            });
        } finally {
            // Stop loading and close the modal in any case
            setIsLoading(false);
            handleClose();
        }
    };

    return (
        <DeleteModal
            handleClose={handleClose}
            handleDone={handleDone}
            title="Delete Channel"
            description="Are you sure you want to delete this scheduled job? This action cannot be undone."
            isOpen={showDeleteJobModal.show}
            isLoading={isLoading}
        />
    );
};

export default DeleteJobModal;
