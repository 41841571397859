import axiosInstance from '../interceptors/auth.interceptor.js';

export const createKnowledgeSpace = async (name, org_id, icon) => {
  try {
    const response = await axiosInstance.post('/space', JSON.stringify({
      name,
      org_id,
      icon
    }));
    return response.data;
  } catch (error) {
    console.error('Error creating knowledge space:', error);
    return null;
  }
};
export const updateKnowledgeSpaceById = async (updatedKnowledgeSpace, space_id) => {
  try {
    const response = await axiosInstance.put(`/space/${space_id}`, updatedKnowledgeSpace);
    return true;
  } catch (error) {
    console.error('Error creating knowledge space:', error);
    return false;
  }
};
export const deleteKnowledgeSpace = async (space_id) => {
  try {
    const response = await axiosInstance.delete(`/space/${space_id}`);
    return true;
  } catch (error) {
    console.error('Error creating knowledge space:', error);
    return false;
  }
};
export const getAllKnowledgeSpace = async () => {
  try {
    const response = await axiosInstance.get('/space');
    return response.data;
  } catch (error) {
    console.error('Error creating knowledge space:', error);
    return null;
  }
};

export const getKnowledgeSpaceById = async (space_id) => {
  try {
    const response = await axiosInstance.get(`/space/${space_id}`);
    return response.data;
  } catch (error) {
    console.error('Error creating knowledge space:', error);
    return null;
  }
};
