import axiosInstance from '../interceptors/auth.interceptor.js';

export const getOrganizationDetails = async (organization_id) => {
    try {
      const response = await axiosInstance.get(`/organization/${organization_id}/details`);
      return response.data;
    } catch (error) {
      console.error('Error getting organization details:', error);
      return null;
    }
  };
export const deleteOrganizationDetails = async (organization_id) => {
    try {
      const response = await axiosInstance.delete(`/organization/${organization_id}/details`);
      return true;
    } catch (error) {
      console.error('Error getting organization details:', error);
      return false;
    }
  };
export const getOrganizationById = async (organization_id) => {
    try {
      const response = await axiosInstance.get(`/organization/${organization_id}`);
      return response.data;
    } catch (error) {
      console.error('Error getting organization details:', error);
      return null;
    }
  };
export const updateOrganizationById = async (organization_id, updatedOrganizationDetails) => {
    try {
      const response = await axiosInstance.put(`/organization/${organization_id}`, updatedOrganizationDetails);
      return true;
    } catch (error) {
      console.error('Error getting organization details:', error);
      return false;
    }
  };
  
export const createOrganization = async (name) => {
    try {
      const response = await axiosInstance.put(`/organization`, JSON.stringify({name}));
      return response.data;
    } catch (error) {
      console.error('Error getting organization details:', error);
      return null;
    }
  };
  