import axiosInstance from '../interceptors/auth.interceptor.js';

export const createSpacePrompt = async (prompt) => {
    try {
        const response = await axiosInstance.post('/promptivity', JSON.stringify({ prompt }));
        return response.data;
    } catch (error) {
        console.error('Error fetching auth token:', error);
        throw error;
    }
}
export const getPromptStatus = async (prompt_id) => {
    try {
        const response = await axiosInstance.get(`/promptivity/${prompt_id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching auth token:', error);
        throw error;
    }
}