import { RiCloseFill } from "react-icons/ri";
import { Dialog, DialogPanel, DialogTitle, Transition } from '@headlessui/react';
import { Fragment, useContext } from "react";
import { Context } from "../../Context/Context";

const DeleteModal = ({ handleClose, handleDone, title, description, isOpen, isLoading }) => {
    const { darkTheme } = useContext(Context);

    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog onClose={handleClose} className="relative z-[999]">
                {/* Backdrop Layer */}
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                <div className={`fixed inset-0 ${darkTheme ? "bg-black" : "bg-white"} bg-opacity-55 backdrop-blur-md`} aria-hidden="true" />
                </Transition.Child>

                {/* Modal Panel */}
                <div className="fixed inset-0 z-[999] mb-36 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <DialogPanel
                                className={`relative transform overflow-hidden rounded-lg shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ${darkTheme
                                    ? 'bg-[#232325] text-white border-[1px] border-[#273037]'
                                    : 'bg-white text-black'
                                    }`}
                            >
                                {/* Modal Header */}
                                <div className={`${darkTheme ? 'bg-[#232325]' : 'bg-white'}`}>
                                    <div className="px-6 py-4 flex items-center justify-between">
                                        <DialogTitle
                                            className={`text-medium font-semibold leading-6 ${darkTheme ? 'text-white' : 'text-gray-900'}`}
                                        >
                                            {title}
                                        </DialogTitle>
                                        <button
                                            type="button"
                                            onClick={handleClose}
                                            className="text-[#545657] hover:text-gray-500"
                                            aria-label="Close modal"
                                        >
                                            <RiCloseFill className="h-6 w-6" />
                                        </button>
                                    </div>
                                    <div className={`${darkTheme ? "border-[1px] border-[#273037]" : "border-[1px] border-gray-200"}`}></div>

                                    {/* Modal Body */}
                                    <div className="mt-4 px-6 py-4">
                                        <p className={`text-sm text-left ${darkTheme ? 'text-gray-300' : 'text-[#273037]'}`}>
                                            {description}
                                        </p>
                                    </div>
                                </div>

                                {/* Modal Footer */}
                                <div className={`px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 ${darkTheme ? 'bg-[#232325]' : 'bg-white'}`}>
                                    <button
                                        type="button"
                                        onClick={handleDone}
                                        disabled={isLoading}
                                        className={`mt-3 inline-flex mx-2 justify-center rounded-md px-4 py-2 text-sm font-semibold shadow-sm ${darkTheme
                                            ? `bg-red-500 text-white ${isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-red-600'}`
                                            : `bg-red-600 text-white ${isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-red-700'}`
                                            }`}
                                    >
                                        {isLoading ? "Deleting..." : "Delete"}
                                    </button>
                                    <button
                                        type="button"
                                        onClick={handleClose}
                                        className={`mt-3 inline-flex justify-center rounded-md px-4 py-2 text-sm font-semibold shadow-sm ${darkTheme
                                            ? 'bg-[#273037] text-white hover:bg-[#3d4144]'
                                            : 'bg-white text-gray-900 ring-1 ring-[#868686] hover:bg-gray-50'
                                            }`}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </DialogPanel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default DeleteModal;
