import { useContext, useState } from 'react';
import { Context } from '../../Context/Context';
import { deleteChannelById } from '../../services/channelService';
import DeleteModal from '../DeleteModal/DeleteModal';
import toast, { toastConfig } from 'react-simple-toasts';
import { useNavigate } from 'react-router-dom';

// Set toast configuration globally for consistent theme
toastConfig({ theme: 'warning' });

const DeleteChannelModal = () => {
    // Context values
    const { showDeleteChannelModal, setShowDeleteChannelModal, setKnowledgespace, setChannelDropdownIndex, darkTheme } = useContext(Context);
    const navigate = useNavigate();
    // State for managing loading state during deletion
    const [isLoading, setIsLoading] = useState(false);

    // Close the modal
    const handleClose = () => setShowDeleteChannelModal({ show: false, channel_id: null, workspace_id: null });

    // Handle deletion of the channel
    const handleDone = async () => {
        try {
            const { workspace_id, channel_id } = showDeleteChannelModal;

            if (!workspace_id || !channel_id) return;

            // Set loading state to prevent multiple clicks
            setIsLoading(true);

            const response = await deleteChannelById(channel_id);

            if (response) {
                // Update the knowledge space by filtering out the deleted channel
                setKnowledgespace((prevData) => {
                    const newData = prevData.map((workspace) => {
                        if (workspace_id === workspace.id) {
                            return {
                                ...workspace,
                                channels: workspace.channels.filter((data) => data.id !== channel_id)
                            };
                        }
                        return workspace;
                    });
                    return newData;
                });

                setChannelDropdownIndex({ workspace: null, channel: null });
                handleClose();

                // Show success toast
                toast("✅ Channel deleted successfully!", {
                    position: 'top-center',
                    className: `${darkTheme ? "bg-black text-green-400" : "bg-white text-green-600"} py-3 px-4 rounded-lg shadow-lg flex items-center gap-2`,
                    style: {
                        border: "1px solid #4CAF50",
                        fontSize: "0.95rem",
                        fontWeight: "500",
                    },
                    clickClosable: true,
                    icon: '✅',
                });
            } else {
                // Show error toast if response is falsy
                toast("🚨 Error: Channel could not be deleted. Please try again.", {
                    position: 'top-center',
                    className: `${darkTheme ? "bg-black text-red-400" : "bg-white text-red-600"} py-3 px-4 rounded-lg shadow-lg flex items-center gap-2`,
                    style: {
                        border: "1px solid #ff4d4d",
                        fontSize: "0.95rem",
                        fontWeight: "500",
                    },
                    clickClosable: true,
                    icon: '🚨',
                });
            }
        } catch (error) {
            console.error(error);
            // Show error toast for exceptions
            toast("🚨 Error encountered while deleting channel. Please try again!", {
                position: 'top-center',
                className: `${darkTheme ? "bg-black text-red-400" : "bg-white text-red-600"} py-3 px-4 rounded-lg shadow-lg flex items-center gap-2`,
                style: {
                    border: "1px solid #ff4d4d",
                    fontSize: "0.95rem",
                    fontWeight: "500",
                },
                clickClosable: true,
                icon: '🚨',
            });
        } finally {
            // Reset loading state
            setIsLoading(false);
            handleClose();
        }
    };

    return (
        <DeleteModal
            handleClose={handleClose}
            handleDone={handleDone}
            title="Delete Channel"
            description="Are you sure you want to delete this channel? This action cannot be undone."
            isOpen={showDeleteChannelModal.show}
            isLoading={isLoading}  // Pass the loading state to the modal component if needed
        />
    );
};

export default DeleteChannelModal;
