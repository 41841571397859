import React, { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { FaArrowRightLong, FaArrowLeftLong } from 'react-icons/fa6';
import { Context } from '../../Context/Context';
import { createCategories, getNewCategories } from '../../services/schedulerJobService';
import { useNavigate } from 'react-router-dom';

const OnboardingPage = () => {
    const [currentSection, setCurrentSection] = useState(0);
    const { darkTheme } = useContext(Context);
    const [onboardingData, setOnboardingData] = useState([]);
    const [selections, setSelections] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchSections();
    }, []);

    const fetchSections = async () => {
        try {
            const response = await getNewCategories();
            if (response?.data) {
                const interestsArray = Object.keys(response.data.interests).map((title) => ({
                    title,
                    options: response.data.interests[title],
                }));
                setOnboardingData(interestsArray);
            }
        } catch (error) {
            console.error("Error getting categories:", error);
        }
    };
    const handleCreate = async () => {
        const interestsData = onboardingData.reduce((acc, section, index) => {
            if (selections[index]) {
                acc[section.title] = [selections[index]];
            }
            return acc;
        }, {});

        const data = {
            interests: interestsData
        };
        try {
            const response = await createCategories(data);
            if (response?.data) {
                navigate('/home');
            }
        } catch (error) {
            console.log("Error creating categories:", error);
        }
    };


    const handleSelection = (option) => {
        const newSelections = [...selections];
        newSelections[currentSection] = option;
        setSelections(newSelections);
    };

    const handleNext = () => {
        if (currentSection < onboardingData.length - 1) {
            setCurrentSection(currentSection + 1);
        }
    };

    const handleBack = () => {
        if (currentSection > 0) {
            setCurrentSection(currentSection - 1);
        }
    };

    const atLeastTwoSelected = selections.filter((selection) => selection !== '').length >= 2;

    return (
        <div
            className={`h-screen w-screen flex flex-col items-center justify-center ${darkTheme ? "bg-[#202020]" : "bg-gradient-to-r from-gray-100 via-gray-200 to-gray-300"
                }`}
        >
            <div
                className={`sm:max-w-7xl 3xl:max-w-[70%] w-full px-24 py-6 ${darkTheme
                    ? "bg-[#181818] shadow-[0_4px_10px_rgba(150,150,150,0.15)]"
                    : "bg-white"
                    } rounded-lg shadow-lg h-[40%] relative`}
            >
                <div className="flex mb-4">
                    {onboardingData.map((section, index) => (
                        <motion.div
                            className={`w-full h-3 cursor-pointer`}
                            onClick={() => index < currentSection && setCurrentSection(index)}
                            key={index}
                        >
                            <motion.div
                                className={`flex-1 h-2 mx-1 rounded-full cursor-pointer ${index < currentSection
                                    ? 'bg-[#f15025]'
                                    : index === currentSection
                                        ? 'bg-[#f15025]'
                                        : 'bg-gray-400'
                                    }`}
                                transition={{ duration: 0.5 }}
                            />
                        </motion.div>
                    ))}
                </div>

                {/* Back Button */}
                <button
                    onClick={handleBack}
                    disabled={currentSection === 0}
                    className={`absolute left-2 top-1/2 transform -translate-y-1/2 flex items-center justify-center p-2 rounded-full h-10 w-10 ${currentSection === 0
                        ? "text-gray-400 cursor-not-allowed"
                        : `text-[#f15025]"}`
                        }`}
                >
                    <FaArrowLeftLong
                        className={`${currentSection === 0 ?
                            `${darkTheme ? "text-gray-300" : "text-gray-700"}`
                            : `text-[#f15025]`
                            }`}
                    />
                </button>

                {/* Main Content */}
                {onboardingData.length > 0 ? (
                    <motion.div
                        key={currentSection}
                        initial={{ opacity: 0, x: 50 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -50 }}
                        transition={{ duration: 0.5 }}
                    >
                        <h2
                            className={`text-2xl font-bold mb-6 ${darkTheme ? "text-gray-400" : "text-gray-800"
                                }`}
                        >
                            {onboardingData[currentSection]?.title}
                        </h2>
                        <div className="grid grid-cols-3 sm:grid-cols-4 gap-3 mb-6 py-4">
                            {onboardingData[currentSection]?.options.map((option) => (
                                <button
                                    key={option}
                                    className={`py-3 px-4 border rounded-full text-sm font-medium transition-all ${selections[currentSection] === option
                                        ? "bg-[#f15025] text-white"
                                        : `${darkTheme
                                            ? "text-gray-300 border-gray-700"
                                            : "text-gray-700 border-gray-300"
                                        }`
                                        } hover:bg-[#f15025] hover:text-white`}
                                    onClick={() => handleSelection(option)}
                                >
                                    {option}
                                </button>
                            ))}
                        </div>
                        <div className="flex justify-end mt-16">
                            <button
                                onClick={() => handleCreate()}
                                disabled={!atLeastTwoSelected}
                                className={`flex items-center justify-center p-2 w-[180px] rounded-md border border-green-600 bg-green-500 text-white transition-all ${!atLeastTwoSelected
                                    ? "opacity-50 cursor-not-allowed"
                                    : "hover:bg-green-600"
                                    }`}
                            >
                                Create Workspace
                            </button>
                        </div>
                    </motion.div>
                ) : (
                    <div className="text-center text-gray-500">Loading...</div>
                )}

                {/* Next Button */}
                <button
                    onClick={handleNext}
                    disabled={currentSection === onboardingData.length - 1}
                    className={`absolute right-2 top-1/2 transform -translate-y-1/2 flex items-center justify-center p-2 rounded-full h-10 w-10 ${currentSection === onboardingData.length - 1
                        ? "text-gray-400 cursor-not-allowed"
                        : `text-[#f15025]"}`
                        }`}
                >
                    <FaArrowRightLong
                        className={`${currentSection === onboardingData.length - 1
                            ? `${darkTheme ? "text-gray-300" : "text-gray-700"}`
                            : ` text-[#f15025]`
                            }`}
                    />
                </button>
            </div>
        </div>
    );
};

export default OnboardingPage;
