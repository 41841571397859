import React, { useRef, useContext } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { format } from 'date-fns';
import { Context } from '../../../Context/Context';

const InsightContainer = ({ darkTheme, loadingInsightsList, filteredInsights, renderInsightCard, searchQuery }) => {
    const scrollContainerRef = useRef(null);
    const { openInsight } = useContext(Context);

    const groupInsightsByDate = (insights) => {
        const groupedInsights = {};
        insights.forEach(insight => {
            const insightDate = format(new Date(insight.time), "MMMM dd, yyyy");
            if (!groupedInsights[insightDate]) {
                groupedInsights[insightDate] = [];
            }
            groupedInsights[insightDate].push(insight);
        });
        return groupedInsights;
    };

    const sortedGroupedInsights = groupInsightsByDate(filteredInsights || []);
    const highlightText = (text) => {
        if (!searchQuery) return text; // Return original text if no query

        const regex = new RegExp(`(${searchQuery})`, 'gi'); // Create a regex to match the query
        const parts = text.split(regex); // Split the text by matched query

        return parts.map((part, index) =>
            part.toLowerCase() === searchQuery.toLowerCase() ? (
                <span key={index} className="bg-yellow-400">{part}</span> // Highlight matched part
            ) : (
                part // Return non-matched part
            )
        );
    };

    return (
        <div
            ref={scrollContainerRef}
            className={`flex flex-col h-screen overflow-y-auto scroll-container custom-scrollbar ${darkTheme ? 'dark-theme' : 'light-theme'}`}
        >
            {loadingInsightsList ? (
                <div className="w-full p-2">
                    {openInsight && <SkeletonTheme baseColor={darkTheme ? "#1e1e1e" : "#dddddd"} highlightColor="#444">
                        <Skeleton height={70} count={10} />
                    </SkeletonTheme>}
                </div>
            ) : (
                Object.entries(sortedGroupedInsights).map(([date, insights]) => (
                    <React.Fragment key={date}>
                        {<div className={`font-bold text-[16px] sm:text-[14px] ${darkTheme ? "text-[#545657]" : "text-[#273037]"} ${openInsight ? "p-2" : "sm:pl-[2px] py-2 text-[13px]"}`}>{date}</div>}
                        <div className={`${openInsight ? "w-auto sm:w-auto  mb-2 sm:mb-0 m-2 sm:p-0 sm:m-2 rounded-lg" : ""} ${darkTheme ? "bg-[#181818] text-white" : "bg-[#F4F4F6] text-black"}`}>
                            {insights.sort((a, b) => new Date(b.time) - new Date(a.time)).map((item, index, arr) => renderInsightCard(item, index, arr)
                            )}
                        </div>
                    </React.Fragment>
                ))
            )}
            <div className="mb-32"></div>
        </div>
    );
};

export default InsightContainer;
