import axiosInstance from '../interceptors/auth.interceptor.js';

export const getDashboardDetails =  async () => {
    try {
      const response = await axiosInstance.get(`/channel/dashboard`);
      return response.data;
    } catch (error) {
      console.error('Error creating insight:', error);
      return null;
    }
  };
