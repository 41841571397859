// import React, { useState, useEffect } from 'react';

// const HeroSection = ({ darkTheme, handleGoogleLogin }) => {
//     const [typedText, setTypedText] = useState('');
//     const [showCursor, setShowCursor] = useState(true);
//     const fullText = 'Build Your Knowledge Engine.';

//     useEffect(() => {
//         let index = 0;
//         let isTyping = true;

//         const animateText = () => {
//             if (isTyping && index <= fullText.length) {
//                 setTypedText(fullText.slice(0, index));
//                 index++;
//             }

//             if (index > fullText.length) {
//                 isTyping = false; // Stop typing after completion
//             } else {
//                 setTimeout(animateText, 100); // Continue typing
//             }
//         };
//         setTimeout(()=>{animateText()}, 200);
//         // Blinking cursor effect
//         const cursorInterval = setInterval(() => {
//             setShowCursor((prev) => !prev);
//         }, 500);

//         return () => {
//             clearTimeout(animateText);
//             clearInterval(cursorInterval);
//         };
//     }, [fullText]);

//     const renderTypedText = () => {
//         const parts = typedText.split(' ');
//         return (
//             <>
//                 {parts.map((word, idx) =>
//                     word === 'Your' ? (
//                         <span key={idx} className="italic">{word} </span> // Make "Your" italic
//                     ) : (
//                         <span key={idx}>{word} </span>
//                     )
//                 )}
//             </>
//         );
//     };

//     return (
//         <div className="flex flex-col items-center justify-center text-left h-fit border-l border-r border-gray-500 mt-10 py-24">
//             <h1 className="text-[36px] sm:text-[42px] lg:text-[64px] 3xl:text-[72px] font-extrabold mb-8 pt-4 leading-tight text-center whitespace-nowrap">
//                 <span className={`bg-clip-text roboto-mono-bold ${darkTheme ? "text-[#f15025]" : "text-black"}`}>
//                     {renderTypedText()}
//                     <span className={`${showCursor ? 'opacity-100' : 'opacity-0'} ml-[-10px]`}>|</span>
//                 </span>
//             </h1>
//             <p className={`text-left sm:text-lg md:text-xl mb-12 ${darkTheme ? "text-[#ADB5BD]" : "text-black"}`}>
//                 Get answers from your expert that learns and compounds knowledge, everyday.
//             </p>
//             <button
//                 type="primary"
//                 className={`py-3 px-12 text-md shadow-lg hover:bg-orange-600 ${
//                     darkTheme ? "bg-[#f15025] text-white" : "bg-[#f15025] text-white"
//                 } hover:opacity-90 transition-all duration-300 border-0 rounded-full`}
//                 onClick={handleGoogleLogin}
//             >
//                 Start Nowㅤ→
//             </button>
//             <div className="py-10 flex justify-center align-center">
//                 <img
//                     src="/hero-img.webp"
//                     className="h-[90%] w-[90%]"
//                     alt="thumbnail"
//                 />
//             </div>
//         </div>
//     );
// };

// export default HeroSection;

import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../Context/Context';
import { FaArrowRightLong } from 'react-icons/fa6';

const HeroSection = ({ darkTheme, handleGoogleLogin }) => {
    return (
        <center className={`w-full max-w-screen-xl h-screen items-center mx-auto px-4 ${darkTheme ? ' text-white' : ' text-black'
            }`}>
            <div className="flex flex-wrap h-screen px-4 sm:pt-24 sm:pb-20 3xl:pt-32 3xl:pb-48 items-center">
                <div className="w-full md:w-1/3 flex justify-center">
                    <div className="relative w-1/2 md:w-3/4">
                        <img
                            src="/page1-removebg.png"
                            className="w-full h-auto"
                            alt="thumbnail"
                            style={{
                                filter: darkTheme
                                    ? 'invert(100%) sepia(20%) saturate(246%) hue-rotate(200deg) brightness(118%) contrast(119%)'
                                    : '',
                            }}
                        />
                    </div>
                </div>
                {/* orange - invert(42%) sepia(85%) saturate(2142%) hue-rotate(347deg) brightness(101%) contrast(95%) */}
                <div className="w-full items-center md:w-2/3">
                    <img
                        src="/hero-img.webp"
                        alt="thumbnail"
                        className="w-full h-auto object-cover rounded-lg"
                    />
                </div>
                <center className={` flex flex-row justify-center space-x-10 w-full items-center sm:pb-8 3xl:pb-48 ${darkTheme ? "text-gray-200" : "text-gray-800"}`}>
                    <p className={`sm:text-lg 3xl:text-[28px] ${darkTheme ? "text-gray-200" : "text-gray-800"}`}>
                        Fast and effective research with Tensorweave
                    </p>
                    <button
                        onClick={handleGoogleLogin}
                        className={`px-6 py-2 sm:text-lg 3xl:text-[28px] rounded-full border-2 bg-[#f15025] ${darkTheme
                            ? 'border-black hover:bg-orange-600 text-white'
                            : 'border-white hover:bg-orange-600 text-white'
                            } transition-colors`}
                    >
                        <div className='flex flex-row'>
                        <span>Try Now</span>
                        <span className='pl-2 mt-[4px]'><FaArrowRightLong/></span>
                        </div>
                    </button>
                </center>

            </div>

            <style jsx>{`
                .hand-drawn-line {
                    animation: draw 1s ease forwards;
                    stroke-dasharray: 1000;
                    stroke-dashoffset: 1000;
                }

                @keyframes draw {
                    to {
                        stroke-dashoffset: 0;
                    }
                }
            `}</style>
        </center>
    );
};

export default HeroSection;