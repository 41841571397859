import React from 'react';
import { motion } from 'framer-motion';

const ChatLoading = () => {
  const bubbleVariants = {
    initial: { scale: 0, opacity: 0 },
    animate: { scale: 1, opacity: 1, transition: { duration: 0.6, yoyo: Infinity } },
  };

  return (
    <div className="flex justify-center items-center h-fit space-x-2">
      {[1, 2, 3].map((_, i) => (
        <motion.div
          key={i}
          className="w-3 h-3 bg-gray-500 rounded-full"
          initial="initial"
          animate="animate"
          variants={bubbleVariants}
          transition={{ delay: i * 0.2 }}
        />
      ))}
    </div>
  );
};

export default ChatLoading;
