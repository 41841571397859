import React, { useContext, useRef, useState } from 'react';
import { Context } from '../../Context/Context.jsx';
import { RiCloseFill } from 'react-icons/ri';
import { Dialog, DialogPanel, DialogTitle, Transition } from '@headlessui/react';
import toast, { toastConfig } from 'react-simple-toasts';
import { Fragment } from 'react';
import { AiOutlineLink } from "react-icons/ai";
import { Button } from 'antd';

toastConfig({ theme: 'warning' });

const ShareInsightModal = () => {
    const { showShareInsightModal, setShowShareInsightModal, darkTheme } = useContext(Context);
    const [accessOption, setAccessOption] = useState('Anyone with link');
    const [emails, setEmails] = useState([]);
    const [emailInput, setEmailInput] = useState('');
    const inputRef = useRef(null);

    const handleClose = () => {
        setShowShareInsightModal(false);
        setEmailInput("");
        setEmails([]);
    };

    const handleCopyLink = () => {
        navigator.clipboard.writeText("https://example.com/insight-link");
        toast("Link copied to clipboard!", {
            position: 'top-center',
            className: `${darkTheme ? "bg-black text-green-400" : "bg-white text-green-600"} py-3 px-4 rounded-lg shadow-lg flex items-center gap-2`,
            style: {
                border: "1px solid #4CAF50",
                fontSize: "0.95rem",
                fontWeight: "500",
            },
        });
    };

    const handleSendInvites = () => {
        toast("Invites sent!", {
            position: 'top-center',
            className: `${darkTheme ? "bg-black text-green-400" : "bg-white text-green-600"} py-3 px-4 rounded-lg shadow-lg flex items-center gap-2`,
            style: {
                border: "1px solid #4CAF50",
                fontSize: "0.95rem",
                fontWeight: "500",
            },
        });
    };

    const handleAddEmail = () => {
        if (emailInput && !emails.includes(emailInput)) {
            setEmails([...emails, emailInput]);
            setEmailInput('');
        }
    };

    const handleRemoveEmail = (emailToRemove) => {
        setEmails(emails.filter(email => email !== emailToRemove));
    };

    const handleEmailInputKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent form submission or input field default behavior
            handleAddEmail();
        }
    };

    const accessOptions = [
        'Anyone with link',
        'Specific people'
    ];

    return (
        <Dialog open={showShareInsightModal} onClose={handleClose} className="relative z-[999]" initialFocus={inputRef}>
            <Transition
                appear
                show={showShareInsightModal}
                as={Fragment}
            >
                <div className={`fixed inset-0 ${darkTheme ? "bg-black" : "bg-white"} bg-opacity-55 backdrop-blur-md`} aria-hidden="true" />
                <div className="fixed inset-0 z-[999] mb-36">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <DialogPanel
                                transition
                                className={`relative transform overflow-hidden rounded-2xl shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ${darkTheme
                                    ? 'bg-[#212529] text-white border-[1px] border-[#273037]'
                                    : 'bg-white text-black border-[1px] border-gray-200'
                                    }`}
                            >

                                <div className={`${darkTheme ? 'bg-[#232325]' : 'bg-white'} rounded-xl`}>
                                    {/* Modal Header */}
                                    <div className="px-6 py-4 flex items-center justify-between">
                                        <DialogTitle
                                            className={`text-[17px] inter-bold leading-6 ${darkTheme ? 'text-white' : 'text-gray-900'}`}
                                        >
                                            Share This Insight
                                        </DialogTitle>
                                        <button
                                            type="button"
                                            onClick={handleClose}
                                            className="text-[#545657] hover:text-gray-500"
                                        >
                                            <RiCloseFill className="h-6 w-6" />
                                        </button>
                                    </div>
                                    <div className={`${darkTheme ? "border-[1px] border-[#273037]" : "border-[1px] border-gray-200"}`}></div>

                                    {/* Direct Link Section */}
                                    <div className="mt-4 px-6 py-2">
                                        <div className="flex items-center justify-between">
                                            <DialogTitle className={`font-bold text-[16px] ${darkTheme ? 'text-white' : 'text-gray-900'}`}>Direct Link</DialogTitle>
                                            <select
                                                className={`text-[16px] border rounded-md px-3 pl-2 py-2 font-bold ${darkTheme ? 'bg-[#1A1B1A] text-white border-[#3d4144]' : 'bg-white text-gray-900 border-[#00000016]'}`}
                                                value={accessOption}
                                                onChange={e => setAccessOption(e.target.value)}
                                            >
                                                {accessOptions.map(option => (
                                                    <option key={option} value={option}>{option}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="mt-4 flex items-center">
                                            <div className={`relative flex items-center w-full border rounded-md ${darkTheme ? 'bg-[#1A1B1A] border-[#3d4144] text-white' : 'bg-[#fbfbfa] border-[#c7c7c7] text-gray-900'}`}>
                                                {/* Icon Inside Input */}
                                                <AiOutlineLink className='absolute left-3 text-[22px] opacity-50' />
                                                {/* Input Field */}
                                                <input
                                                    readOnly
                                                    value="https://example.com/insight-link"
                                                    className={`w-full py-2 pl-10 pr-20 border-none bg-transparent focus:outline-none ${darkTheme ? 'text-white' : 'text-gray-900'}`}
                                                />
                                                {/* Copy Button Inside Input */}
                                                <button
                                                    onClick={handleCopyLink}
                                                    className={`absolute right-2 py-1 px-4 rounded-md ${darkTheme ? 'bg-transparent text-white' : 'bg-transparent text-gray-900'} focus:outline-none`}
                                                >
                                                    Copy Link
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Invite with Email Section */}
                                    <div className="mt-2 px-6 py-2">
                                        <div className='flex items-center justify-between'>
                                            <DialogTitle className={`font-bold text-[16px] ${darkTheme ? 'text-white' : 'text-gray-900'}`}>Invite with Email</DialogTitle>
                                        </div>
                                        <div className="flex items-center mt-2">
                                            <input
                                                value={emailInput}
                                                onChange={e => setEmailInput(e.target.value)}
                                                onKeyDown={handleEmailInputKeyDown}
                                                placeholder="Enter email"
                                                className={`w-full py-1 px-1 border rounded-md focus:outline-none ${darkTheme
                                                    ? 'bg-[#1A1B1A] text-white placeholder-gray-400 border-[#3d4144] focus:shadow-[0_0_3px_1px_rgba(59,140,246,0.75),0_0_1px_1px_rgba(59,130,246,0.5),0_0_1px_1px_rgba(59,130,246,0.25)]'
                                                    : 'bg-[#fbfbfa] text-gray-900 placeholder-[#acacad] border-[#c7c7c7] focus:shadow-[0_0_3px_1px_rgba(59,130,246,0.75),0_0_1px_1px_rgba(59,130,246,0.5),0_0_1px_1px_rgba(59,130,246,0.25)]'}`}
                                            />
                                            <Button
                                                onClick={handleSendInvites}
                                                className={`ml-2 px-4 py-2 text-[14px] rounded-lg font-semibold ${darkTheme ? "bg-white-300 text-black" : "bg-black text-white"}`}
                                            >
                                                Send Invites
                                            </Button>
                                        </div>
                                        {/* Display added emails */}
                                        <div className="flex flex-wrap mt-4">
                                            {emails.map(email => (
                                                <div key={email} className={`flex items-center px-3 py-1 rounded-full m-1 ${darkTheme ? 'bg-[#2a2d30] text-white' : 'bg-gray-200 text-gray-900'}`}>
                                                    <span className="mr-2">{email}</span>
                                                    <button onClick={() => handleRemoveEmail(email)} className="text-red-500">✕</button>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="mt-2 px-6">
                                        <div className='flex items-center justify-between'>
                                            <DialogTitle className={`font-bold text-[16px] ${darkTheme ? 'text-white' : 'text-gray-900'}`}>In this Insight</DialogTitle>
                                        </div>

                                        {/* Users List Section */}
                                        <div className="mt-4 space-y-4 overflow-y-auto">
                                            {/* Example of user profile list item */}
                                            {[
                                                { id: 1, name: 'John Doe', email: 'john.doe@example.com', role: 'Owner', imageUrl: '/image.png' },
                                                { id: 2, name: 'Jane Smith', email: 'jane.smith@example.com', role: 'Can Edit', imageUrl: '/image.png' },
                                                { id: 3, name: 'Alex Johnson', email: 'alex.johnson@example.com', role: 'Can View', imageUrl: '/image.png' }
                                            ].map(user => (
                                                <div key={user.id} className={`flex items-center justify-between py-2 px-4 rounded-md ${darkTheme ? 'bg-[#2a2d30] text-white' : 'bg-[#f8f9fa] text-gray-900'} shadow-sm`}>
                                                    <div className='flex flex-row'>
                                                        <img src={user.imageUrl} alt={`${user.name}'s profile`} className="w-10 h-10 rounded-full object-cover" />

                                                        <div className="ml-4">
                                                            <p className="font-semibold text-left text-[14px]">{user.name}</p>
                                                            <p className="text-[12px] opacity-70">{user.email}</p>
                                                        </div>
                                                    </div>

                                                    <div className="text-sm font-medium">
                                                        <p className={`text-left ${user.role === 'Owner' ? 'text-blue-500' : 'text-green-500'}`}>{user.role}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                {/* Footer Buttons */}
                                <div className={`px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 ${darkTheme ? 'bg-[#232325]' : 'bg-white'}`}>
                                    <Button
                                        onClick={handleCopyLink}
                                        className={`mt-3 mx-2 px-4 py-2 text-[14px] rounded-lg font-semibold ${darkTheme ? "bg-white-300 text-black" : "bg-black text-white"}`}
                                    >
                                        <AiOutlineLink className='text-[22px] opacity-50' /> Copy Link
                                    </Button>
                                </div>
                            </DialogPanel>
                        </Transition.Child>
                    </div>
                </div>
            </Transition>
        </Dialog>
    );
};

export default ShareInsightModal;
