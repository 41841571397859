import axiosInstance from '../interceptors/auth.interceptor.js';

export const createChannel = async (space_id, name) => {
    try {
      const response = await axiosInstance.post('/channel', JSON.stringify({
        name,
        space_id
      }));
      return response.data;
    } catch (error) {
      console.error('Error creating channel:', error);
      return null;
    }
  };
  
export const getLatestChannelData = async (channel_id) => {
    try {
      const response = await axiosInstance.post(`/channel/${channel_id}/action`, {
        "action" : "latest"
      });
      return response.data;
    } catch (error) {
      console.error('Error creating channel:', error);
      return null;
    }
  };
  

  export const getChannelDetails = async (channel_id) => {
    try {
      const response = await axiosInstance.get(`/channel/${channel_id}/details`);
      return response.data;
    } catch (error) {
      console.error('Error getting channel details:', error);
      return null;
    }
  };
  export const getLatestActionsStatus = async (channel_id, action_id) => {
    try {
      const response = await axiosInstance.get(`/channel/${channel_id}/action/${action_id}`);
      return response.data;
    } catch (error) {
      console.error('Error getting channel details:', error);
      return null;
    }
  };

  export const updateChannelById = async (updatedChannelDetails, channel_id) => {
    try {
      const response = await axiosInstance.put(`/channel/${channel_id}`, updatedChannelDetails);
      return true;
    } catch (error) {
      console.error('Error creating channel:', error);
      return false;
    }
  };
  export const deleteChannelById = async (channel_id) => {
    try {
      const response = await axiosInstance.delete(`/channel/${channel_id}`);
      return true;
    } catch (error) {
      console.error('Error creating channel:', error);
      return false;
    }
  };
