import axios from 'axios';

// Create the main axios instance for general API requests
const axiosInstance = axios.create({
  baseURL: 'https://api.tensorweave.com/api/v1',
  headers: {
    'Content-Type': 'application/json',
    'ngrok-skip-browser-warning': 'true'
  }
});

// Create a separate axios instance for handling refresh token requests
const refreshAxiosInstance = axios.create({
  baseURL: 'https://api.tensorweave.com/api/v1',
  headers: {
    'Content-Type': 'application/json',
    'ngrok-skip-browser-warning': 'true'
  }
});

// Request Interceptor for the main axios instance
axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Response Interceptor for the main axios instance
axiosInstance.interceptors.response.use(
  response => response,  // Return the response if successful
  async (error) => {
    const originalRequest = error.config;
    
    // Check if the error is due to an expired access token (401 status) and it's not a retry
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;  // Set the retry flag

      try {
        // Use the separate axios instance to handle the refresh token
        const user_email = localStorage.getItem('user_email');
        const refresh_token = localStorage.getItem('refresh_token');
        
        // Call refresh token endpoint using refreshAxiosInstance
        const response = await refreshAxiosInstance.get('/auth/refresh/google', {
          params: { email: user_email, refresh_token: refresh_token },
        });

        // If refresh was successful, store new access token
        const newToken = response.data.data.access_token;
        localStorage.setItem('access_token', newToken);

        // Update the authorization header for the main axios instance
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
        
        // Retry the original request with the new access token
        return axiosInstance(originalRequest);

      } catch (e) {
        // If the refresh token also fails, handle the error and log the user out
        console.error('Refresh token verification failed:', e);

        if (e.response && e.response.status === 401) {
          handleLogout();  // Call the logout function to clean up and redirect
        }
      }
    }

    return Promise.reject(error);
  }
);

// Separate function to handle session cleanup and redirection
const handleLogout = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('user_email');

  // Redirect user to login or home page
  window.location.href = '/';
};

export default axiosInstance;
