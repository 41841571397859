// hooks/useFetchData.js
import { useContext, useEffect } from "react";
import { Context } from "../Context/Context";
import { getDashboardDetails } from "../services/dashboardService.js";

export const useDashData = (setIsLoading) => {
    const { setDashboardData } = useContext(Context);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const dashboard_data = await getDashboardDetails();
            if(dashboard_data?.data){
                setDashboardData(dashboard_data.data)
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        finally{
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []); // Empty dependency array ensures it runs only once
};
