import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const GoogleAuthCallback = () => {
  const location = useLocation();

  useEffect(() => {
    const handleCallback = () => {
      const urlParams = new URLSearchParams(location.search);
      const code = urlParams.get('code');

      if (code && window.opener) {
        window.opener.postMessage(
          { type: 'GOOGLE_AUTH_SUCCESS', code },
          window.location.origin
        );
        
        // Close the popup window after a short delay
        setTimeout(() => {
          window.close();
        }, 100);
      } else {
        console.error('No code found in URL params or no opener window');
        window.location.href = '/';
      }
    };

    handleCallback();
  }, [location]);

  return <div>Authentication successful. This window will close shortly...</div>;
};

export default GoogleAuthCallback;