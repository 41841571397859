import React, { useContext, useRef, useState } from 'react';
import { motion } from "framer-motion";
import { Context } from '../../Context/Context';
import { MdOutlineArrowUpward } from 'react-icons/md';
import { HiArrowRight } from 'react-icons/hi';
import { createOnboardingChannelData } from '../../services/promptServices';
import { useNavigate } from 'react-router-dom';

const SuperOnboard = () => {
    const [focused, setFocused] = useState(false);
    const [promptText, setPromptText] = useState('');
    const { darkTheme } = useContext(Context);
    const promptRef = useRef(null);
    const navigate = useNavigate();

    const handleSubmit = () => {
        try {
            if (promptText.trim()) {
                // const response = createOnboardingChannelData(promptText.trim());
                // if (response?.data) {
                //     navigate('/home');
                //     setPromptText('');
                // }
            }
        } catch (error) {
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && e.shiftKey) {
            e.preventDefault();
            handleSubmit();
        }
    };

    return (
        <div className={`w-full flex justify-center items-center h-screen ${darkTheme ? "bg-[#202020]" : "bg-[#f6f7f7]"}`}>
            <div className="sm:max-w-4xl 3xl:max-w-[60%] w-full px-4">
                <div className="flex items-center justify-between mb-6">
                    <div className={`flex items-center gap-2 ${darkTheme ? "text-[#909090]" : "text-zinc-800"}`}>
                        <h2 className="text-lg font-medium">Tell us what you want to create</h2>
                        <motion.div
                            animate={{ x: [0, 4, 0] }}
                            transition={{ 
                                duration: 1.5,
                                repeat: Infinity,
                                ease: "easeInOut"
                            }}
                        >
                            <HiArrowRight className="h-5 w-5 text-[#f15025]" />
                        </motion.div>
                    </div>
                </div>
                
                <div
                    className={`
                        w-full 
                        rounded-xl
                        p-4
                        transition-all
                        duration-300
                        border
                        ${darkTheme ? "bg-[#181818] border-[#ffffff16]" : "bg-[#fafbfa]"}
                    `}
                >
                    <div className="flex flex-col gap-3">
                        <div className="flex-1">
                            <textarea
                                placeholder="Give your prompt here..."
                                className={`
                                    w-full 
                                    bg-transparent 
                                    border-none 
                                    outline-none 
                                    resize-none
                                    min-h-[100px]
                                    text-base
                                    ${darkTheme ? "text-zinc-200 placeholder-zinc-500" : "text-zinc-800 placeholder-zinc-400"}
                                `}
                                value={promptText}
                                onChange={(e) => {
                                    setPromptText(e.target.value);
                                }}
                                onFocus={() => setFocused(true)}
                                onBlur={() => setFocused(false)}
                                onKeyDown={handleKeyDown}
                            />
                        </div>

                        <div className="flex items-center justify-between pt-2 border-t border-zinc-800">
                            <div className="flex items-center gap-2">
                            </div>
                            <button
                                onClick={handleSubmit}
                                className={`sm:p-2 3xll:p-4 text-white rounded-full ${promptText?.length > 0 ? "bg-[#f15025] hover:bg-[#f15025]" : "bg-[#404040]"}`}
                            >
                                <motion.div
                                    animate={{ rotate: promptText?.length > 0 ? 90 : 0 }}
                                    transition={{ duration: 0.5, ease: "easeInOut" }}
                                >
                                    <MdOutlineArrowUpward className="h-5 w-5" />
                                </motion.div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SuperOnboard;