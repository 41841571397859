import axiosInstance from '../interceptors/auth.interceptor.js';

export const getAuthToken = async (username, password) => {
    try {
        const response = await axiosInstance.post('/auth/token', JSON.stringify({ username, password}));
        const { access_token } = response.data;
        localStorage.setItem('accessToken', access_token);
        return access_token;
    } catch (error) {
        console.error('Error fetching auth token:', error);
        throw error; 
    }
};

export const getUserProfile = async () => {
    try {
        const response = await axiosInstance.get('/user/profile');
        return response.data;
    } catch (error) {
        console.error('Error fetching user profile:', error);
        throw error; 
    }
};
export const updateUser = async (updatedUserData) => {
    try {
        const response = await axiosInstance.put('/user',updatedUserData);
        return true;
    } catch (error) {
        console.error('Error fetching user profile:', error);
        return false; // Rethrow or handle the error as needed
    }
};

export const createUser = async (username, name, email,password) => {
    try {
        const response = await axiosInstance.post('/auth/signup', JSON.stringify({ username, name, email, password }));
        const { access_token } = response.data;
        localStorage.setItem('accessToken', access_token);
        return access_token;
    } catch (error) {
        console.error('Error fetching auth token:', error);
        throw error; // Rethrow or handle the error as needed
    }

}