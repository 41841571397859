import axiosInstance from "../interceptors/axiosChat.interceptor";

export const createChannelInsightThread = async (channel_id, insight_id) => {
    try {
      const response = await axiosInstance.post(`/thread/channel/${channel_id}/insight/${insight_id}`, JSON.stringify({
      }));
      return response.data;
    } catch (error) {
      console.error('Error creating insight:', error);
      return null;
    }
  };
export const createThreadConversation = async (thread_id, question) => {
    try {
      const response = await axiosInstance.post(`/thread/${thread_id}/conversation`, JSON.stringify({
        question
      }));
      return response.data;
    } catch (error) {
      console.error('Error creating insight:', error);
      return null;
    }
  };
export const getAllThreadsByChannel = async (channel_id) => {
    try {
      const response = await axiosInstance.get(`/thread?channel_id=${channel_id}`);
      return response.data;
    } catch (error) {
      console.error('Error creating insight:', error);
      return null;
    }
  };
export const getThreadByThreadId = async (channel_id, thread_id) => {
    try {
      const response = await axiosInstance.get(`/thread?channel_id=${channel_id}&thread_id=${thread_id}`);
      return response.data;
    } catch (error) {
      console.error('Error creating insight:', error);
      return null;
    }
  };
export const getThreadByChannelInsight = async (channel_id, insight_id) => {
    try {
      const response = await axiosInstance.get(`/thread?channel_id=${channel_id}&insight_id=${insight_id}`);
      return response.data;
    } catch (error) {
      console.error('Error creating insight:', error);
      return null;
    }
  };
export const deleteThreadByThreadId = async (thread_id) => {
    try {
      const response = await axiosInstance.delete(`/thread/${thread_id}`);
      return response.data;
    } catch (error) {
      console.error('Error creating insight:', error);
      return null;
    }
  };
