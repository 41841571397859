import { useContext, useState } from 'react';
import { Context } from '../../Context/Context';
import { deleteKnowledgeSpace } from '../../services/workspaceService';
import DeleteModal from '../DeleteModal/DeleteModal';
import toast, { toastConfig } from 'react-simple-toasts';

toastConfig({ theme: 'warning' });

const DeleteSpaceModal = () => {
    const { showDeleteSpaceModal, setShowDeleteSpaceModal, setPathIndex, setKnowledgespace, darkTheme } = useContext(Context);
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => setShowDeleteSpaceModal({ show: false, id: null });

    const handleDone = async () => {
        try {
            const space_id = showDeleteSpaceModal.id;
            if (!space_id) return;

            setIsLoading(true);

            const response = await deleteKnowledgeSpace(space_id);

            if (response) {
                setKnowledgespace((prevData) => prevData.filter((data) => data.id !== space_id));

                setPathIndex((prevPathIndex) => ({
                    ...prevPathIndex,
                    workspace_id: null,
                    channel_id: null,
                    insight_id: null
                }));

                // Show success toast
                toast("✅ Knowledge space deleted successfully!", {
                    position: 'top-center',
                    className: `${darkTheme ? "bg-black text-green-400" : "bg-white text-green-600"} py-3 px-4 rounded-lg shadow-lg flex items-center gap-2`,
                    style: {
                        border: "1px solid #4CAF50",
                        fontSize: "0.95rem",
                        fontWeight: "500",
                    },
                    clickClosable: true,
                    icon: '✅',
                });

                // Close the modal after deletion
                handleClose();
            } else {
                // Show error toast if response is falsy
                toast("🚨 Error: Knowledge space could not be deleted. Please try again.", {
                    position: 'top-center',
                    className: `${darkTheme ? "bg-black text-red-400" : "bg-white text-red-600"} py-3 px-4 rounded-lg shadow-lg flex items-center gap-2`,
                    style: {
                        border: "1px solid #ff4d4d",
                        fontSize: "0.95rem",
                        fontWeight: "500",
                    },
                    clickClosable: true,
                    icon: '🚨',
                });
            }
        } catch (error) {
            console.error("Error deleting knowledge space:", error);
            // Show error toast for exceptions
            toast("🚨 Error encountered while deleting knowledge space. Please try again!", {
                position: 'top-center',
                className: `${darkTheme ? "bg-black text-red-400" : "bg-white text-red-600"} py-3 px-4 rounded-lg shadow-lg flex items-center gap-2`,
                style: {
                    border: "1px solid #ff4d4d",
                    fontSize: "0.95rem",
                    fontWeight: "500",
                },
                clickClosable: true,
                icon: '🚨',
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <DeleteModal 
            handleClose={handleClose}
            handleDone={handleDone}
            title="Delete Knowledge Space"
            description="Are you sure you want to delete this space? This action cannot be undone."
            isOpen={showDeleteSpaceModal.show}
            isLoading={isLoading} 
        />
    );
};

export default DeleteSpaceModal;
