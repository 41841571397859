import axiosInstance from '../interceptors/auth.interceptor.js';

export const createChannelJob = async (channel_id, jobDetails) => {
    try {
      const response = await axiosInstance.post(`/channel/${channel_id}/job`,jobDetails);
      return response.data;
    } catch (error) {
      console.error('Error creating insight:', error);
      return null;
    }
};
export const updateChannelJob = async (job_id, updatedJobDetails) => {
    try {
      const response = await axiosInstance.put(`/job/${job_id}`,updatedJobDetails);
      return response.data;
    } catch (error) {
      console.error('Error creating insight:', error);
      return null;
    }
};
export const deleteChannelJob = async (job_id) => {
    try {
      const response = await axiosInstance.delete(`/job/${job_id}`);
      return response.data;
    } catch (error) {
      console.error('Error creating insight:', error);
      return null;
    }
};
export const getChannelJob = async (job_id) => {
    try {
      const response = await axiosInstance.get(`/job/${job_id}`);
      return response.data;
    } catch (error) {
      console.error('Error creating insight:', error);
      return null;
    }
};
export const getAllJobs = async () => {
    try {
      const response = await axiosInstance.get(`/job`);
      return response.data;
    } catch (error) {
      console.error('Error creating insight:', error);
      return null;
    }
};
export const getNewCategories = async () =>{
  try {
    const response = await axiosInstance.get(`/user/interests`);
    return response.data;
  } catch (error) {
      console.log("", error);
  }
}
export const createCategories = async (selected_data) =>{
  try {
    const response = await axiosInstance.post(`/user/interests`, JSON.stringify(selected_data));
    return response.data;
  } catch (error) {
      console.log("error getting categories", error);
  }
}
