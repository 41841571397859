import React, { Fragment, useRef, useState, useContext, useEffect } from 'react';
import { RiCloseFill } from 'react-icons/ri';
import { Dialog, DialogPanel, DialogTitle, Transition } from '@headlessui/react';
import { FiChevronLeft, FiChevronRight, FiX } from 'react-icons/fi';
import { ImSpinner2 } from 'react-icons/im';
import { Context } from '../../Context/Context.jsx';
import { Button } from 'antd';
import { createChannelJob } from '../../services/schedulerJobService.js';
import toast from 'react-simple-toasts';

const SchedulerModal = () => {
    const { showSchedulerModal, setShowSchedulerModal, darkTheme, pathIndex } = useContext(Context);
    const [keywords, setKeywords] = useState([]);
    const [keywordInput, setKeywordInput] = useState('');
    const [selectedSites, setSelectedSites] = useState([]);
    const [customUrl, setCustomUrl] = useState('');
    const [customUrls, setCustomUrls] = useState([]);
    const [scrapeValue, setScrapeValue] = useState(1); // Initial value for duration
    const [selectedUnit, setSelectedUnit] = useState('day'); // Initial unit (Hours, Days, Weeks)
    const [jobName, setJobName] = useState("");
    const [jobDescription, setJobDescription] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [errors, setErrors] = useState({}); // Error state
    const [loading, setLoading] = useState(false);
    const scrollRef = useRef(null);

    const popularSites = [
        { name: 'Seeking Alpha', logo: 'https://pbs.twimg.com/profile_images/534299535552421888/eHacq8EQ.png', url: 'https://seekingalpha.com/' },
        { name: 'Yahoo Finance', logo: 'https://static-00.iconduck.com/assets.00/yahoo-icon-1024x1024-n9hkvsuz.png', url: 'https://finance.yahoo.com/' },
        { name: 'CNBC', logo: 'https://th.bing.com/th/id/OIP.-vF1Thn_cCQKYHL9jAA8uAAAAA?rs=1&pid=ImgDetMain', url: 'https://www.cnbc.com/' },
        { name: 'NY Times', logo: 'https://i.pinimg.com/originals/97/7a/31/977a31b32c998dda750cea2db6a7ebf7.png', url: 'https://www.nytimes.com/' },
        { name: 'Wall Street Journal', logo: 'https://th.bing.com/th/id/OIP.JiFHirZPnrImy0tLlCGl6AHaEC?rs=1&pid=ImgDetMain', url: 'https://www.wsj.com/' },
        { name: 'Forbes', logo: 'https://static.vecteezy.com/system/resources/previews/019/040/337/non_2x/forbes-black-logo-on-white-background-free-vector.jpg', url: 'https://www.forbes.com/' },
        { name: 'Fortune', logo: 'https://www.underconsideration.com/brandnew/archives/fortune_social_media_icon.png', url: 'https://fortune.com/' },
        { name: 'Bloomberg', logo: 'https://d2q79iu7y748jz.cloudfront.net/s/_squarelogo/2a94a39377d37de96a4dd01debee53ac', url: 'https://www.bloomberg.com/' },
        { name: 'Investopedia', logo: 'https://yt3.ggpht.com/a-/AN66SAzKBlobU6--Py0I_jgZqmRxeda8DmDlLeeQhQ=s900-mo-c-c0xffffffff-rj-k-no', url: 'https://www.investopedia.com/' },
        { name: 'Medium', logo: 'https://www.underconsideration.com/brandnew/archives/medium_2017_monogram.png', url: 'https://medium.com/' },
    ];

    useEffect(() => {
        // Check if all required fields (except selectedSites) are filled
        if (
            jobName.trim() !== '' &&
            jobDescription.trim() !== '' &&
            keywords.length > 0 &&
            scrapeValue > 0
        ) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [jobName, jobDescription, keywords, scrapeValue]);

    const handleValueChange = (newValue) => {
        if (newValue > 0) setScrapeValue(newValue); // Ensure value is always positive
    };

    const handleUnitSelect = (unit) => {
        setSelectedUnit(unit);
    };

    const handleClose = () => {
        setShowSchedulerModal(false);
        setCustomUrl('');
        setKeywordInput('');
        setCustomUrls([]);
        setKeywords([]);
        setScrapeValue(1);
        setSelectedSites([]);
        setJobDescription('');
        setJobName('');
        setSelectedUnit('day');
        setErrors({}); // Clear errors on close
    };

    const handleSiteSelect = (site) => {
        setSelectedSites(prev =>
            prev.includes(site.url) ? prev.filter(s => s !== site.url) : [...prev, site.url]
        );
    };

    const handleSubmit = async () => {
        // Validate fields
        const newErrors = {};
        if (!jobName.trim()) {
            newErrors.jobName = 'Scheduler name cannot be empty.';
        }
        if (!jobDescription.trim()) {
            newErrors.jobDescription = 'Description cannot be empty.';
        }
        if (keywords.length === 0) {
            newErrors.keywords = 'At least one keyword is required.';
        }
        if (scrapeValue <= 0) {
            newErrors.scrapeValue = 'Scrape value must be greater than 0.';
        }

        // Add more validations as needed

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setLoading(true);
        try {
            const gap = scrapeValue + selectedUnit;
            const jobDetails = {
                "name": jobName,
                "keywords": keywords,
                "description": jobDescription,
                "popular_sites": selectedSites,
                "frequency": gap,
            };
            const response = await createChannelJob(pathIndex.channel_id, jobDetails);
            if (response.success) {
                setTimeout(() => {
                    setLoading(false);
                    setShowSchedulerModal(false);
                }, 2000);
                setCustomUrl('');
                setKeywordInput('');
                setCustomUrls([]);
                setKeywords([]);
                setScrapeValue(1);
                setSelectedSites([]);
                setSelectedUnit('day');
                setJobDescription('');
                setJobName('');
                toast("✅ Scheduler Job Created successfully!", {
                    position: 'top-center',
                    className: `${darkTheme ? "bg-black text-green-400" : "bg-white text-green-600"} py-3 px-4 rounded-lg shadow-lg flex items-center gap-2`,
                    style: {
                        border: "1px solid #4CAF50",
                        fontSize: "0.95rem",
                        fontWeight: "500",
                    },
                    clickClosable: true,
                    icon: '✅',
                });
            }
            else {
                setLoading(false);
                toast("🚨 Failed to create Scheduler Job. Please try again!", {
                    position: 'top-center',
                    className: `${darkTheme ? "bg-black text-red-400" : "bg-white text-red-600"} py-3 px-4 rounded-lg shadow-lg flex items-center gap-2`,
                    style: {
                        border: "1px solid #ff4d4d",
                        fontSize: "0.95rem",
                        fontWeight: "500",
                    },
                    clickClosable: true,
                    icon: '🚨',
                });
            }
        } catch (error) {
            setLoading(false);
            console.log("Error creating Scheduler", error);
            toast("🚨 Error encountered while scheduling insights. Please try again!", {
                position: 'top-center',
                className: `${darkTheme ? "bg-black text-red-400" : "bg-white text-red-600"} py-3 px-4 rounded-lg shadow-lg flex items-center gap-2`,
                style: {
                    border: "1px solid #ff4d4d",
                    fontSize: "0.95rem",
                    fontWeight: "500",
                },
                clickClosable: true,
                icon: '🚨',
            });
        }
    };

    const scrollLeft = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: -100, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: 100, behavior: 'smooth' });
        }
    };

    const themeClasses = {
        background: darkTheme ? 'bg-black' : 'bg-white',
        text: darkTheme ? 'text-white' : 'text-gray-900',
        border: darkTheme ? "border-[#ffffff16]" : "border-[#00000016]",
        button: darkTheme ? 'bg-[#1A1B1A] text-white hover:text-black' : 'bg-[#fbfbfa] text-black hover:text-white',
        buttonSelected: darkTheme ? 'bg-white text-black' : 'bg-black text-white',
        input: darkTheme ? 'bg-gray-800 text-white border-[#ffffff16]' : 'bg-white text-gray-900 border-[#00000016]',
    };

    const handleAddKeyword = () => {
        if (keywordInput && !keywords.includes(keywordInput)) {
            setKeywords([...keywords, keywordInput]);
            setKeywordInput('');
            setErrors(prev => ({ ...prev, keywords: undefined })); // Clear keyword error
        }
    };

    const handleAddSite = () => {
        if (customUrl && !selectedSites.includes(customUrl)) {
            setSelectedSites(prev => [...prev, customUrl]);
        }
        if (customUrl && !customUrls.includes(customUrl)) {
            setCustomUrls(prev => [...prev, customUrl]);
            setCustomUrl('');
        }
    };

    const handleRemoveKeyword = (keywordToRemove) => {
        setKeywords(keywords.filter(keyword => keyword !== keywordToRemove));
    };

    const handleRemoveUrl = (urlToRemove) => {
        setCustomUrls(customUrls.filter(url => url !== urlToRemove));
        setSelectedSites(selectedSites.filter(url => url !== urlToRemove));
    };

    const handleKeywordInputKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleAddKeyword();
        }
    };

    const handleUrlInputKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleAddSite();
        }
    };

    return (
        <Dialog as="div" open={showSchedulerModal} onClose={handleClose} className="relative z-[999]">
            <Transition appear show={showSchedulerModal} as={Fragment}>
                <div className={`fixed inset-0 ${darkTheme ? "bg-black" : "bg-white"} bg-opacity-55 backdrop-blur-md`} aria-hidden="true" />
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <DialogPanel
                                className={`relative transform overflow-hidden rounded-2xl shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ${darkTheme
                                    ? 'bg-black text-gray-500 border border-[#273037]'
                                    : 'bg-white text-black border border-gray-200'
                                    }`}
                            >
                                {/* Header */}
                                <div className="px-6 py-4 flex items-center justify-between">
                                    <DialogTitle
                                        className={`text-[17px] inter-bold leading-6 ${darkTheme ? 'text-gray-500' : 'text-gray-900'}`}
                                    >
                                        Scheduler
                                    </DialogTitle>
                                    <button
                                        type="button"
                                        onClick={handleClose}
                                        className="text-[#545657] hover:text-gray-500"
                                    >
                                        <RiCloseFill className="h-6 w-6" />
                                    </button>
                                </div>
                                <div className={`${darkTheme ? "border border-[#273037]" : "border border-gray-200"}`}></div>

                                {/* Keywords Input Section */}
                                <div className="mt-2 px-6 py-2">
                                    {/* Scheduler Name */}
                                    <div className='flex justify-left mt-2'>
                                        <DialogTitle className={`font-bold text-[14px] ${darkTheme ? 'text-gray-500' : 'text-gray-900'}`}>Scheduler Name*</DialogTitle>
                                    </div>
                                    <div className="flex items-center mt-2 mb-2">
                                        <input
                                            value={jobName}
                                            onChange={e => {
                                                setJobName(e.target.value);
                                                setErrors(prev => ({ ...prev, jobName: undefined })); // Clear jobName error
                                            }}
                                            placeholder="Enter Scheduler's Name"
                                            className={`w-full py-1 px-4 border rounded-md focus:outline-none ${errors.jobName ? 'border-red-500' : darkTheme
                                                ? 'bg-[#1A1B1A] text-white placeholder-gray-400 border-[#3d4144] focus:shadow-[0_0_3px_1px_rgba(59,140,246,0.75),0_0_1px_1px_rgba(59,130,246,0.5),0_0_1px_1px_rgba(59,130,246,0.25)]'
                                                : 'bg-[#fbfbfa] text-black placeholder-[#acacad] border-[#c7c7c7] focus:shadow-[0_0_3px_1px_rgba(59,130,246,0.75),0_0_1px_1px_rgba(59,130,246,0.5),0_0_1px_1px_rgba(59,130,246,0.25)]'
                                                }`}
                                        />
                                    </div>
                                    {errors.jobName && (
                                        <p className="text-red-500 text-sm mb-2">{errors.jobName}</p>
                                    )}

                                    {/* Description */}
                                    <div className='flex justify-left mt-2'>
                                        <DialogTitle className={`font-bold text-[14px] ${darkTheme ? 'text-gray-500' : 'text-gray-900'}`}>Description*</DialogTitle>
                                    </div>
                                    <div className="flex items-center mt-2">
                                        <input
                                            value={jobDescription}
                                            onChange={e => {
                                                setJobDescription(e.target.value);
                                                setErrors(prev => ({ ...prev, jobDescription: undefined })); // Clear jobDescription error
                                            }}
                                            placeholder="Enter Description"
                                            className={`w-full py-1 px-4 border rounded-md focus:outline-none ${errors.jobDescription ? 'border-red-500' : darkTheme
                                                ? 'bg-[#1A1B1A] text-white placeholder-gray-400 border-[#3d4144] focus:shadow-[0_0_3px_1px_rgba(59,140,246,0.75),0_0_1px_1px_rgba(59,130,246,0.5),0_0_1px_1px_rgba(59,130,246,0.25)]'
                                                : 'bg-[#fbfbfa] text-black placeholder-[#acacad] border-[#c7c7c7] focus:shadow-[0_0_3px_1px_rgba(59,130,246,0.75),0_0_1px_1px_rgba(59,130,246,0.5),0_0_1px_1px_rgba(59,130,246,0.25)]'
                                                }`}
                                        />
                                    </div>
                                    {errors.jobDescription && (
                                        <p className="text-red-500 text-sm mb-2">{errors.jobDescription}</p>
                                    )}

                                    {/* Keywords */}
                                    <div className='flex justify-left mt-2'>
                                        <DialogTitle className={`font-bold text-[14px] ${darkTheme ? 'text-gray-500' : 'text-gray-900'}`}>Keywords*</DialogTitle>
                                    </div>
                                    <div className="flex items-center mt-2">
                                        <input
                                            value={keywordInput}
                                            onChange={e => setKeywordInput(e.target.value)}
                                            onKeyDown={handleKeywordInputKeyDown}
                                            placeholder="Enter a keyword"
                                            className={`w-full py-1 px-4 border rounded-md focus:outline-none ${errors.keywords ? 'border-red-500' : darkTheme
                                                ? 'bg-[#1A1B1A] text-white placeholder-gray-400 border-[#3d4144] focus:shadow-[0_0_3px_1px_rgba(59,140,246,0.75),0_0_1px_1px_rgba(59,130,246,0.5),0_0_1px_1px_rgba(59,130,246,0.25)]'
                                                : 'bg-[#fbfbfa] text-black placeholder-[#acacad] border-[#c7c7c7] focus:shadow-[0_0_3px_1px_rgba(59,130,246,0.75),0_0_1px_1px_rgba(59,130,246,0.5),0_0_1px_1px_rgba(59,130,246,0.25)]'
                                                }`}
                                        />
                                        <Button onClick={handleAddKeyword} className={`ml-2 px-4 py-2 ${themeClasses.button}`}>
                                            Add
                                        </Button>
                                    </div>
                                    {errors.keywords && (
                                        <p className="text-red-500 text-sm mb-2">{errors.keywords}</p>
                                    )}
                                    <div className="flex flex-wrap mt-4">
                                        {keywords.map(keyword => (
                                            <div key={keyword} className={`flex items-center px-3 py-1 rounded-full m-1 ${themeClasses.buttonSelected}`}>
                                                {keyword}
                                                <FiX className="ml-2 cursor-pointer" onClick={() => handleRemoveKeyword(keyword)} />
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                {/* Popular Sites Section */}
                                <div className="px-6 relative">
                                    <div className='flex justify-left'>
                                        <DialogTitle className={`font-bold text-[14px] ${darkTheme ? 'text-gray-500' : 'text-gray-900'}`}>Sources (Optional)</DialogTitle>
                                    </div>
                                    <button onClick={scrollLeft} className="absolute left-3 top-[58%] transform -translate-y-1/2 z-[999] p-1 rounded-full shadow-md bg-white dark:bg-gray-800">
                                        <FiChevronLeft className="text-gray-600 dark:text-gray-400" />
                                    </button>
                                    <button onClick={scrollRight} className="absolute right-3 top-[58%] transform -translate-y-1/2 z-[999] p-1 rounded-full shadow-md bg-white dark:bg-gray-800">
                                        <FiChevronRight className="text-gray-600 dark:text-gray-400" />
                                    </button>
                                    <div ref={scrollRef} className="flex overflow-x-auto gap-2 py-2 px-6 hide-scrollbar">
                                        {popularSites.map((site) => (
                                            <button
                                                key={site.url}
                                                onClick={() => handleSiteSelect(site)}
                                                className={`flex-shrink-0 w-16 h-16 rounded-full border-2 flex items-center justify-center transition-all ${selectedSites.includes(site.url) ? `${darkTheme ? "border-white" : "border-black"} shadow-md` : 'border-transparent hover:border-[#00000016]'
                                                    }`}
                                            >
                                                <img src={site.logo} alt={site.name} className="w-12 h-12 rounded-full object-cover" />
                                            </button>
                                        ))}
                                    </div>
                                </div>

                                {/* OR Divider */}
                                <div className="flex items-center my-1 px-6">
                                    <hr className="flex-grow border-[#00000016] dark:border-gray-700" />
                                    <span className="mx-2 text-sm text-gray-500">OR</span>
                                    <hr className="flex-grow border-[#00000016] dark:border-gray-700" />
                                </div>

                                {/* Custom URL Input */}
                                <div className="px-6 py-2">
                                    <div className='flex justify-left'>
                                        <DialogTitle className={`font-bold text-[14px] ${darkTheme ? 'text-gray-500' : 'text-gray-900'}`}>Custom URL</DialogTitle>
                                    </div>
                                    <div className="flex mt-1">
                                        <input
                                            type="url"
                                            placeholder="https://example.com"
                                            value={customUrl}
                                            onChange={(e) => setCustomUrl(e.target.value)}
                                            onKeyDown={handleUrlInputKeyDown}
                                            className={`w-full py-1 px-4 border rounded-md focus:outline-none ${darkTheme
                                                ? 'bg-[#1A1B1A] text-white placeholder-gray-400 border-[#3d4144] focus:shadow-[0_0_3px_1px_rgba(59,140,246,0.75),0_0_1px_1px_rgba(59,130,246,0.5),0_0_1px_1px_rgba(59,130,246,0.25)]'
                                                : 'bg-[#fbfbfa] text-black placeholder-[#acacad] border-[#c7c7c7] focus:shadow-[0_0_3px_1px_rgba(59,130,246,0.75),0_0_1px_1px_rgba(59,130,246,0.5),0_0_1px_1px_rgba(59,130,246,0.25)]'
                                                } ${errors.scrapeValue ? 'border-red-500' : ''}`}
                                        />
                                        <Button onClick={handleAddSite} className={`ml-2 px-4 py-2 ${themeClasses.button}`}>
                                            Add
                                        </Button>
                                    </div>
                                    {errors.scrapeValue && (
                                        <p className="text-red-500 text-sm mb-2">{errors.scrapeValue}</p>
                                    )}
                                    <div className="flex flex-wrap mt-2">
                                        {customUrls.map(url => (
                                            <div key={url} className={`flex items-center px-3 py-1 rounded-full m-1 ${themeClasses.buttonSelected}`}>
                                                {url}
                                                <FiX className="ml-2 cursor-pointer" onClick={() => handleRemoveUrl(url)} />
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                {/* Scraping Duration Selection */}
                                <div className="mt-2 px-6 py-2">
                                    <div className="flex justify-left items-center">
                                        <DialogTitle className={`font-bold text-[14px] ${darkTheme ? 'text-gray-500' : 'text-gray-900'}`}>
                                            Every
                                        </DialogTitle>

                                        <div className="flex items-center ml-4">
                                            <p
                                                onClick={() => handleValueChange(scrapeValue - 1)}
                                                className={`mr-2 px-2 py-1 rounded-md text-lg font-bold cursor-pointer hover:bg-gray-200`}
                                            >
                                                -
                                            </p>
                                            <p
                                                type="number"
                                                value={scrapeValue}
                                                className={`w-16 text-center border rounded-md ${darkTheme ? 'bg-[#1A1B1A] text-gray-400' : 'bg-gray-100 text-gray-900'}`}
                                            >{scrapeValue}</p>
                                            <p
                                                onClick={() => handleValueChange(scrapeValue + 1)}
                                                className={`ml-2 px-2 py-1 rounded-md text-lg font-bold cursor-pointer hover:bg-gray-200`}
                                            >
                                                +
                                            </p>
                                        </div>

                                        {/* Time Units Selection (Days, Weeks) */}
                                        <div className="flex gap-0 border-[1px] ml-4 rounded-md overflow-hidden">
                                            {['day', 'week'].map((unit) => (
                                                <Button
                                                    key={unit}
                                                    onClick={() => handleUnitSelect(unit)}
                                                    className={`px-3 py-1 w-12 text-sm border-none rounded-none transition-colors ${selectedUnit === unit ? themeClasses.buttonSelected : themeClasses.button}`}
                                                >
                                                    {unit.charAt(0).toUpperCase() + unit.slice(1)}
                                                </Button>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                {/* Submit Button */}
                                <div className={`px-2 py-3 sm:flex sm:flex-row-reverse sm:px-2`}>
                                    <Button
                                        className={`mt-3 mx-2 px-4 py-2 text-[14px] rounded-lg font-semibold border-0 ${darkTheme ? "bg-blue-600 text-white" : "bg-black text-gray-500"}`}
                                        onClick={() => {
                                            if (isButtonDisabled || loading) {
                                                return;
                                            }
                                            handleSubmit();
                                        }}
                                    // disabled={isButtonDisabled || loading}
                                    >
                                        {loading ? (
                                            <ImSpinner2 className="animate-spin h-5 w-5 mr-2" />
                                        ) : (
                                            'Schedule Insight'
                                        )}
                                    </Button>
                                </div>
                            </DialogPanel>
                        </Transition.Child>
                    </div>
                </div>
            </Transition>
        </Dialog>
    );

};

export default SchedulerModal;
