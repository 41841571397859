// import React, { useState, useRef, useEffect, useContext } from 'react';
// import { motion, AnimatePresence } from 'framer-motion';
// import { Send, Paperclip } from 'lucide-react';
// import { Context } from '../../../Context/Context';
// import { Breadcrumb } from 'antd';
// import { MdOutlineArrowUpward } from "react-icons/md";

// const ChatPage = () => {
//   const [messages, setMessages] = useState([]);
//   const [inputMessage, setInputMessage] = useState('');
//   const messagesEndRef = useRef(null);
//   const { knowledgespace, pathIndex, darkTheme, summaryInfo } = useContext(Context);
//   const [path, setPath] = useState([]);
//   const scrollContainerRef = useRef(null);


//   useEffect(() => {
//       const updatePath = () => {
//           let newPath = [];
//           if (pathIndex.workspace_id !== null) {
//               const workspace = knowledgespace.find(
//                   (ws) => ws.id === pathIndex.workspace_id
//               );
//               if (workspace) {
//                   newPath.push(workspace.name);
//                   if (pathIndex.channel_id !== null) {
//                       const channel = workspace.channels.find(
//                           (ch) => ch.id === pathIndex.channel_id
//                       );
//                       if (channel) {
//                           newPath.push(channel.name);
//                       }
//                   }
//               }
//           }
//           setPath(newPath);
//       };

//       updatePath();
//   }, [pathIndex, knowledgespace]);


//   const handleSendMessage = () => {
//     if (inputMessage.trim() !== '') {
//       setMessages([...messages, { text: inputMessage, sender: 'user' }]);
//       setInputMessage('');

//       // Bot response (word-by-word generation)
//       const botResponse = "Beep boop! I’m a chatbot, not a mind reader—yet. What can I help you with today? (I promise I won't take over the world... this week). Beep boop! I’m a chatbot, not a mind reader—yet. What can I help you with today? (I promise I won't take over the world... this week). Beep boop! I’m a chatbot, not a mind reader—yet. What can I help you with today? (I promise I won't take over the world... this week). Beep boop! I’m a chatbot, not a mind reader—yet. What can I help you with today? (I promise I won't take over the world... this week)    \n Beep boop! I’m a chatbot, not a mind reader—yet. What can I help you with today? (I promise I won't take over the world... this week)";
//       const botResponseWords = botResponse.split(' ');
//       let currentWordIndex = 0;
//       let generatedMessage = '';

//       const intervalId = setInterval(() => {
//         if (currentWordIndex < botResponseWords.length) {
//           generatedMessage += (currentWordIndex > 0 ? ' ' : '') + botResponseWords[currentWordIndex];
//           setMessages(prev => {
//             const updatedMessages = [...prev];
//             updatedMessages[updatedMessages.length - 1] = { text: generatedMessage, sender: 'bot' };
//             return updatedMessages;
//           });
//           currentWordIndex++;
//         } else {
//           clearInterval(intervalId);
//         }
//       }, 100); // Adjust delay for word speed (200ms per word)

//       // Add initial empty bot message to trigger animation
//       setMessages(prev => [...prev, { text: '', sender: 'bot' }]);
//     }
//   };

//   return (
//     <div className={`flex flex-col h-full mt-[1px] ${darkTheme ? 'bg-[#181818] text-white' : 'bg-[#fafbfa] text-gray-900'}`}>
//       <div className={`flex justify-between border-b p-[1px] ${darkTheme ? "border-[#ffffff16]" : "border-[#00000016]"}`}>
//         <div className="p-2 mx-2 min-h-[40px]">
//           <Breadcrumb
//             separator={<span className={`${darkTheme ? "text-white" : "text-[#2f2f2f]"}`}>{">>"}</span>}
//             items={path.map((p, index) => ({
//               title: <span className={`${darkTheme ? "text-white" : "text-[#2f2f2f]"} font-semibold`}>{p.split(' ')[0].charAt(0).toUpperCase() + p.split(' ')[0].slice(1)}</span>,
//               key: index,
//             }))}
//           />
//         </div>
//         <div className="flex p-1">
//         </div>
//       </div>
//       <div className={`flex-grow overflow-y-auto p-10 scroll-container custom-scrollbar ${darkTheme ? 'dark-theme' : 'light-theme'}`}>
//         <AnimatePresence>
//           {messages.map((message, index) => (
//             <motion.div
//               key={index}
//               initial={{ opacity: 0, y: 20 }}
//               animate={{ opacity: 1, y: 0 }}
//               exit={{ opacity: 0, y: -20 }}
//               className={`mb-4 py-3 ${message.sender === 'user' ? 'text-right' : 'text-left'}`}
//             >
//               <div
//                 className={`inline-block p-3 rounded-lg ${message.sender === 'user'
//                     ? 'bg-[#343434] text-white'
//                     : darkTheme
//                       ? ''
//                       : 'bg-gray-200'
//                   }`}
//               >
//                 {(message.sender !== 'user') && <img src='/gpt.png' alt="gpt" className='w-4 h-4 inline mr-2 mb-1' />}{message.text}
//               </div>
//             </motion.div>
//           ))}
//         </AnimatePresence>
//         <div ref={messagesEndRef} />
//       </div>

//       <div className={`py-4 px-8 ${darkTheme ? 'bg-[#181818]' : 'bg-gray-100'}`}>
//         <div className={`flex items-center ${darkTheme ? "bg-[#202020] " : "bg-white"} pl-4 px-2 rounded-full py-1`}>
//           <input
//             type="text"
//             value={inputMessage}
//             onChange={(e) => setInputMessage(e.target.value)}
//             onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
//             className={`flex-grow p-2 rounded-l-lg focus:outline-none ${darkTheme ? "bg-[#202020] " : "bg-white"}`}
//             placeholder="Type a message..."
//           />
//           <button
//             onClick={handleSendMessage}
//             className={`p-2 text-white rounded-full ${inputMessage.length > 0 ? "bg-[#f15025] hover:bg-[#f15025]":"bg-[#404040]"} transition-colors`}
//           >
//             <MdOutlineArrowUpward size={20} />
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ChatPage;


import React, { useState, useRef, useEffect, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { MdOutlineArrowUpward } from "react-icons/md";
import { Context } from '../../../Context/Context';
import { createThreadConversation } from '../../../services/chatService.js';
import { Breadcrumb } from 'antd';
import { IoMdArrowRoundBack } from 'react-icons/io';
import ReactMarkdown from 'react-markdown';
import ChatLoading from '../../../components/ChatAnswerLoader/ChatAnswerLoader.jsx';

const ChatPage = ({ selectedThreadId, setSelectedThreadId }) => {
  const [inputMessage, setInputMessage] = useState('');
  const [localMessages, setLocalMessages] = useState([]); // Local messages state
  const [latestQuestion, setLatestQuestion] = useState(null); // State for the latest question
  const messagesContainerRef = useRef(null);
  const { knowledgespace, pathIndex, darkTheme, messages, setMessages, answerLoading, setAnswerLoading, allThreadData } = useContext(Context);
  const [path, setPath] = useState([]);
  const inputRef = useRef(null);
  useEffect(() => {
    const fetchDataAndLoadMessages = () => {
      if (allThreadData && allThreadData?.length > 0) setSelectedThreadId(allThreadData[0].thread_id);

      if (messages?.length) {
        let lastQuestion = null;

        const cleanedMessages = messages.map((message, index) => {
          if (message?.text) {
            if (message.sender === 'bot' && index === messages.length - 1) {
              const questionMatch = message.text.match(/<question>(.*?)<\/question>/);
              lastQuestion = questionMatch ? questionMatch[1] : null;  // Save the question
            }

            const cleanedText = message.text.replace(/<question>.*?<\/question>/, '');
            return { ...message, text: cleanedText };
          }
          return message;
        });

        setLocalMessages(cleanedMessages);  // Set local messages without questions

        if (lastQuestion) {
          setLatestQuestion(lastQuestion);  // Set the last extracted question to the state
        } else {
          setLatestQuestion(null);  // Clear latest question if no question is found
        }
      } else {
        setLocalMessages([]);  
        setLatestQuestion(null);  // Clear the latest question
      }
    };

    fetchDataAndLoadMessages();
  }, [messages, allThreadData]);

  useEffect(() => {
    const updatePath = () => {
      let newPath = [];
      if (pathIndex.workspace_id !== null) {
        const workspace = knowledgespace.find(ws => ws.id === pathIndex.workspace_id);
        if (workspace) {
          newPath.push(workspace.name);
          if (pathIndex.channel_id !== null) {
            const channel = workspace.channels.find(ch => ch.id === pathIndex.channel_id);
            if (channel) newPath.push(channel.name);
          }
        }
      }
      setPath(newPath);
    };
    updatePath();
  }, [pathIndex, knowledgespace]);

  const handleSendMessage = async (messageToSend) => {
    try {
      if (messageToSend.trim() === '') return;
  
      const userMessage = { text: messageToSend, sender: 'user' };  
      setLocalMessages((prev) => [...prev, userMessage]);  
      setMessages((prev) => [...prev, userMessage]);  
      setAnswerLoading(true);
      
      setInputMessage('');
      const response = await createThreadConversation(selectedThreadId, messageToSend);
      let botResponse = response?.data.answer;  
      const questionMatch = botResponse.match(/<question>(.*?)<\/question>/);
      const question = questionMatch ? questionMatch[1] : null;

      setLatestQuestion(question);  

      botResponse = botResponse.replace(/<question>.*?<\/question>/, '');
      const botMessage = { text: botResponse, sender: 'bot' };  
      
      setLocalMessages((prev) => [...prev, botMessage]);
      setMessages((prev) => [...prev, botMessage]);
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setAnswerLoading(false);
    }
  };
  
  const bubbleVariants = {
    initial: { scale: 0, opacity: 0 },
    animate: { scale: 1, opacity: 1, transition: { duration: 0.6, yoyo: Infinity } },
  };

  const scrollToBottom = () => {
    if (messagesContainerRef?.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  };
  useEffect(() => {
    inputRef.current?.focus();
  });

  useEffect(() => {
    scrollToBottom();
  }, [localMessages]);

  const handleInputChangeMessage = (e) => {
    setInputMessage(e.target.value);
  };

  const customComponents = {
    // Customize bold text (strong)
    strong: ({ children }) => (
      <strong className={`font-bold ${darkTheme ? "text-gray-100" : ""} lato-font`}>
        {children}
      </strong>
    ),
    // Customize italic text (em)
    // em: ({ children }) => (
    //   <em className="italic text-blue-500">
    //     {children}
    //   </em>
    // )
  };


  return (
    <div
      className={`flex flex-col h-full mt-[1px] border-b ${darkTheme ? "border-[#ffffff16]" : "border-[#00000016]"} ${darkTheme ? 'bg-[#181818] text-white' : 'bg-[#fafbfa] text-gray-900'}`}
    >
      <div className={`flex justify-left border-b p-[1px] ${darkTheme ? "border-[#ffffff16]" : "border-[#00000016]"}`}>
        <div className="p-2 mx-2 min-h-[40px]">
          Chat
        </div>
      </div>

      <div
        ref={messagesContainerRef}
        className={`relative flex-grow overflow-y-auto ml-[-40px] sm:px-16 2xl:px-24 3xll:px-48 scroll-container custom-scrollbar ${darkTheme ? 'dark-theme' : 'light-theme'}`} style={{ zIndex: 1 }}
      >
        <AnimatePresence>
          {localMessages?.map((message, index) => (
            <div
              key={index}
              className={`mb-4 py-3 rounded-full ${index === 0 ? "mt-8" : ""} ${(index === localMessages.length - 1 && !answerLoading && !latestQuestion) ? "mb-[90px]" : ""} ${message.sender === 'user' ? 'text-right' : 'text-left'}`}
            >
              <div className={`inline-flex px-4 py-1 rounded-xl leading-loose ${message.sender === 'user' ? (darkTheme ? 'bg-[#353535] text-white' : 'bg-gray-200 text-black') : darkTheme ? '' : ''}`}>
                {message.sender !== 'user' && <img src='/logo1.png' alt="gpt" className={`w-4 h-4 inline mr-2 mb-1 mt-[10px] ${darkTheme ? "" : "filter invert"}`} />}
                <ReactMarkdown
                  className={`leading-loose text-left sm:text-[16px] 3xll:text-[18px] ${darkTheme ? "text-gray-300" : ""}`}
                  components={customComponents}
                >
                  {message.text}</ReactMarkdown>
              </div>
            </div>
          ))}
        </AnimatePresence>

        {answerLoading && (
          <div className="flex justify-left items-center h-fit space-x-2 my-4 mb-[110px]">
            {[1, 2, 3].map((_, i) => (
              <motion.div
                key={i}
                className="w-3 h-3 bg-gray-500 rounded-full"
                initial="initial"
                animate="animate"
                variants={bubbleVariants}
                transition={{ delay: i * 0.2 }}
              />
            ))}
          </div>
        )}

        {latestQuestion && !answerLoading && (
          <div className={`flex justify-start items-center space-x-2 mt-4 ml-4 mb-[50px] cursor-pointer py-1 px-8 rounded-lg border ${darkTheme ? "border-[#ffffff16] hover:bg-[#151515]" : "border-[#00000016] hover:bg-gray-100"}`}
            onClick={() => handleSendMessage(latestQuestion)}
          >
            <p className={`text-left sm:text-[16px] 3xll:text-[18px] hover:text-[#f15025] ${darkTheme ? 'text-gray-200' : 'text-gray-700'}`}>
              {latestQuestion}
            </p>
          </div>
        )}
      </div>

      {/* Floating Message Input */}
      <div className={`flex justify-center sm:py-4 3xl:py-6 px-8 ${darkTheme ? '' : ''}`}>
        <div className={`flex sm:w-[90%] 3xl:w-[65%] 3xll:w-[70%] ${darkTheme ? "bg-[#323232] shadow-[0_0_3px_1px_rgba(59,140,246,0.75),0_0_1px_1px_rgba(59,130,246,0.5),0_0_1px_1px_rgba(59,130,246,0.25)]" : "bg-white shadow-[0_0_3px_1px_rgba(59,130,246,0.75),0_0_1px_1px_rgba(59,130,246,0.5),0_0_1px_1px_rgba(59,130,246,0.25)]"} pl-4 px-2 rounded-full py-1`}>
          <textarea
            type="text"
            ref={inputRef}
            value={inputMessage}
            onChange={handleInputChangeMessage}
            onKeyDown={(e) => {
              if ((e.key === "Enter" && e.shiftKey) || (e.key === "Enter" && e.ctrlKey)) {
                e.preventDefault();
                const cursorPosition = e.target.selectionStart;
                const updatedMessage =
                  inputMessage.slice(0, cursorPosition) + '\n' + inputMessage.slice(cursorPosition);
                handleInputChangeMessage({ target: { value: updatedMessage } });
              } else if (e.key === "Enter") {
                e.preventDefault();
                handleSendMessage(inputMessage);
              }
            }}
            className={`flex-grow sm:py-1 3xl:py-4 px-2 rounded-xl justify-center items-center focus:outline-none ${darkTheme
                ? "bg-[#323232] text-white placeholder-gray-300 border-[#3d4144]"
                : "bg-[#fbfbfa] text-black placeholder-[#acacad] border-[#c7c7c7]"
              }`}
            placeholder="Chat with the Insight..."
            rows={1}
            style={{
              "scrollbar-width": "none"
            }}
          />
          <button
            className={`sm:p-2 3xll:p-4 text-white rounded-full ${inputMessage?.length > 0 ? "bg-[#f15025] hover:bg-[#f15025]" : "bg-[#404040]"}`}
            onClick={() => { handleSendMessage(inputMessage) }}
          >
            <MdOutlineArrowUpward className="h-6 w-6" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChatPage;
