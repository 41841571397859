import React, { useContext, useEffect, useRef, useState } from 'react';
import { motion } from "framer-motion";
import { Context } from '../../Context/Context';
import { HiArrowRight } from 'react-icons/hi';
import { Dialog, Transition } from '@headlessui/react';
import { HiHashtag } from "react-icons/hi2";
import { createChannel } from '../../services/channelService';
import { useNavigate } from 'react-router-dom';


const AddChannelModal = () => {
    const { showChannelModal, setShowChannelModal, setChannelData, setList, setInsightDetails } = useContext(Context);
    const [channelName, setChannelName] = useState('');
    const { darkTheme } = useContext(Context);
    const { setKnowledgespace } = useContext(Context);
    const [loading, setLoading] = useState(false); // Loading state
    const inputRef = useRef(null);
    const handleClose = () => {
        setShowChannelModal({ show: false, id: null });
        setChannelName('');
    }
    const navigate = useNavigate();

    const handleDone = async () => {
        setLoading(true);
        try {
            handleClose();
            if (channelName.trim() === '') return;
            const channelData = await createChannel(showChannelModal.id, channelName);
            if (channelData) {
                setChannelData(channelData.data);
                navigate(`/home/sp/${showChannelModal.id}/ch/${channelData.data.channel_id}`);
                setList([]);
                setInsightDetails(null);
                setKnowledgespace((prevData) => {
                    const newData = prevData.map((workspace) => {
                        if (workspace.id === showChannelModal.id) {
                            return {
                                ...workspace,
                                channels: [
                                    ...workspace.channels,
                                    {
                                        id: channelData.data.channel_id,
                                        name: channelData.data.name,
                                        icon: <HiHashtag />,
                                        path: ["Knowledge Spaces", workspace.name, channelData.data.name],
                                        unread_insights_count: 0,
                                    },
                                ],
                            };
                        }
                        return workspace;
                    });
                    return newData;
                });
            }
            setChannelName('');
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false); // End loading
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleDone();
        }
    };


    return (
        <Dialog open={showChannelModal.show} onClose={handleClose} className="relative z-[999]" initialFocus={inputRef}>
            <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className={`fixed inset-0 ${darkTheme ? "bg-black" : "bg-white"} bg-opacity-65 backdrop-blur-md`} aria-hidden="true" />
            </Transition.Child>
            <div className="fixed inset-0 z-[999] mb-48 w-screen overflow-visible rounded-lg">
                <div className="flex min-h-full items-center justify-center p-4 text-center rounded-lg">
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >

                        <Dialog.Panel
                            className={`relative transform overflow-visible rounded-xl transition-all sm:my-8 sm:max-w-3xl w-full ${darkTheme
                                ? 'bg-none text-white'
                                : 'bg-none text-black'
                                }`}
                        >
                            <div className={`items-center rounded-xl w-[100%] border border-[#f15025] ${darkTheme ? "bg-[#000]" : "bg-white"}`}>

                                <div className="w-full rounded-xl p-6">
                                    <div className="flex flex-col gap-3">
                                        <textarea
                                            ref={inputRef}
                                            placeholder="Channel Name"
                                            value={channelName}
                                            onChange={(e) => setChannelName(e.target.value)}
                                            className={`w-full px-3 bg-transparent border-none outline-none resize-none text-base ${darkTheme ? "text-zinc-200 placeholder-zinc-500" : "text-zinc-800 placeholder-zinc-400"}`}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </div>
                                </div>
                                <div className={`px-6 py-1 flex justify-end sm:px-6 border-t ${darkTheme ? "border-[#404040]" : ""}`}>
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center gap-2"></div>
                                        <button
                                            onClick={handleDone}
                                            className={`sm:p-2 3xll:p-4 rounded-full ${(channelName?.length > 0) ? "text-[#f15025] hover:text-[#f15025]" : "text-[#404040]"}`}
                                        >
                                            <motion.div
                                            >
                                                <HiArrowRight className="h-5 w-5" /> {/* Arrow pointing right */}
                                            </motion.div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog >
    );
};

export default AddChannelModal;
