import React, { useContext, useEffect, useState, useCallback } from "react";
import { Button } from "antd";
import { Context } from "../../Context/Context";
import { useNavigate } from "react-router-dom";
import { Spotlight } from "../../components/ui/Spotlight";
import { jwtDecode } from "jwt-decode";
import axiosInstance from "../../interceptors/auth.interceptor.js";
import FeatureSection from "../../components/FeatureSection/FeatureSection.jsx";
import HeroSection from "../../components/HeroSection/Hero.jsx";
import { BsArrowReturnRight } from "react-icons/bs";
import { Sun, Moon } from 'lucide-react';

const LandingPage = () => {
  const navigate = useNavigate();
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const redirectUri = "https://tensorweave.com/auth/google/callback";
  const handleGoogleLogin = useCallback(async () => {
    if (isAuthenticating) return;

    try {
      setIsAuthenticating(true);
      const response = await axiosInstance.get("/auth/login/google", {
        params: { redirect_uri: redirectUri },
      });
      const authUrl = response.data.authorization_url;
      const width = 500;
      const height = 600;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2.5;
      window.open(
        authUrl,
        "googleAuthPopup",
        `width=${width},height=${height},left=${left},top=${top}`
      );
    } catch (error) {
      console.error("Error during Google login initiation:", error);
      setIsAuthenticating(false);
    }
    finally {
      setIsAuthenticating(false);
    }
  }, [redirectUri, isAuthenticating]);

  const handleAuthSuccess = useCallback(
    async (code) => {
      try {
        const response = await axiosInstance.get("/auth/auth/google", {
          params: { code, redirect_uri: redirectUri },
        });

        const accessToken = response.data.data.access_token;
        const decodedToken = jwtDecode(accessToken);

        localStorage.setItem("access_token", accessToken);
        localStorage.setItem("user_email", decodedToken.email);
        localStorage.setItem("refresh_token", response.data.data.refresh_token);

        if (response.data.data.is_first_time) {
          navigate("/onboarding")
        }
        else {
          navigate("/home");
        }
      } catch (error) {
        console.error("Error during Google authentication:", error);
        // Handle error (e.g., show error message to user)
      } finally {
        setIsAuthenticating(false);
      }
    },
    [navigate, redirectUri]
  );

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== window.location.origin) return;

      if (event.data.type === "GOOGLE_AUTH_SUCCESS") {
        handleAuthSuccess(event.data.code);
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, [handleAuthSuccess]);

  const { darkTheme, toggleTheme } = useContext(Context);

  return (
    <div
      className={`h-screen ${darkTheme ? "bg-[#181818]" : "bg-gradient-to-b from-gray-50 to-gray-200"
        } overflow-y-auto custom-scrollbar scroll-container`}
    >
      <div className="fixed bottom-10 right-10">
        <button
          onClick={toggleTheme}
          className={`relative overflow-hidden rounded-full p-2
        transition-all duration-500 ease-in-out
        hover:scale-105 active:scale-95
        ${darkTheme
              ? 'bg-slate-800 text-yellow-400 hover:bg-slate-700'
              : 'bg-blue-100 text-[#f15025] hover:bg-blue-200'
            }
        shadow-lg hover:shadow-xl
        group flex items-center gap-2 px-4`}
        >
          <div className="relative flex items-center gap-4 z-10">
            {/* Sun Icon */}
            <Sun
              className={`h-5 w-5 transition-all duration-500 
                ${darkTheme ? 'rotate-0 scale-100' : 'rotate-90 scale-0'}
                absolute left-0 text-yellow-400`}
            />
            {/* Moon Icon */}
            <Moon
              className={`h-5 w-5 transition-all duration-500
                ${darkTheme ? '-rotate-90 scale-0' : 'rotate-0 scale-100'}
                absolute left-0 text-orange-500`}
            />
            {/* Text */}
            <span className={`ml-7 text-sm font-medium`}>
              {darkTheme ? 'Light' : 'Dark'} Mode
            </span>
          </div>

          {/* Gradient Background */}
          <div
            className={`absolute inset-0 transform transition-transform duration-500 z-0
            ${darkTheme
                ? 'translate-x-full bg-gradient-to-r from-slate-900 to-slate-800'
                : 'translate-x-0 bg-gradient-to-r from-blue-50 to-blue-100'
              }`}
          />
        </button>
      </div>

      <header className="sm:px-[100px] 3xl:px-[200px] py-2 flex flex-row items-center justify-center sm:justify-between mx-auto border-b border-gray-500">
        <div className="flex items-center justify-center mb-4 sm:mb-0 ">
          <img
            src="/logo1.png"
            alt="Logo"
            width="60px"
            height="46px"
            className={`transition-transform ${darkTheme ? "" : "filter invert"
              } ${darkTheme ? "hover:scale-105" : ""}`}
          />
          <span
            className={`text-[18px] font-bold ml-3 ${darkTheme ? "text-white" : "text-gray-900"
              }`}
          >
            Tensorweave
          </span>
        </div>
        <div className="xsm:mb-4 hidden sm:inline-block sm:mb-0">
          <p
            className={`text-[18px] mr-10 cursor-pointer pr-[3px] flex items-center hover:text-[#ADB5BD] ${darkTheme ? "text-white" : "text-black"}`}
            onClick={handleGoogleLogin}
          >
            <BsArrowReturnRight className="mr-2" /> {/* Add margin-right to space it from text */}
            Log in
          </p>
        </div>

      </header>

      <main className="h-screen mx-auto lg:flex-row items-center lg:items-center">
        <div className="items-center text-center pt-4 sm:px-[100px] 3xl:px-[200px] mt-[-55px] ">
          <HeroSection darkTheme={darkTheme} handleGoogleLogin={handleGoogleLogin} />
        </div>
      </main>
      <footer
        className={`mt-12 py-8 border-t ${darkTheme
          ? "border-[#ffffff16] text-gray-300"
          : "border-[#00000016] text-gray-600"
          }`}
      >
        <center className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div>
            <div className="md:mb-0">
              <h3
                className={`text-[18px] 2xl:text-[24px] 3xl:text-[28px] font-semibold mb-4 ${darkTheme ? "text-white" : "text-gray-900"
                  }`}
              >
                About Us
              </h3>
              <p className="text-[16px] 2xl:text-[18px] 3xl:text-[24px]">
                Tensorweave is your AI-powered knowledge management solution,
                helping you organize and access information effortlessly.
              </p>
            </div>
          </div>
          <div
            className={`mt-8 pt-8 border-t ${darkTheme ? "border-[#ffffff16]" : "border-gray-200"
              } text-sm 2xl:text-lg 3xl:text-xl text-center`}
          >
            © {new Date().getFullYear()} Tensorweave. All rights reserved.
          </div>
        </center>
      </footer>
    </div>
  );
};

export default LandingPage;
