import React, { useState, useEffect, useContext } from "react";
import { Breadcrumb } from "antd";
import { format } from "date-fns";
import { IoChatbubblesSharp } from "react-icons/io5";
import { PiNotepadFill } from "react-icons/pi";
import { useParams } from "react-router-dom";
import { createChannelInsightThread, getThreadByChannelInsight } from "../../services/chatService";
import { createNote } from "../../services/notesService";
import Sidebar from "../../components/Sidebar/Sidebar";
import SideNavigation from "../../components/SideNavigation/SideNavigation";
import { Context } from "../../Context/Context";
import RightSidebar from "../../components/RightSidebar/RightSidebar";

const ScreenShare = () => {
  const [insightDetails, setInsightDetails] = useState({
    "insight_id": "a0a31be9-51a8-423e-9811-e7405a301e87",
    "channels": [
      "0d8525e2-d050-4219-be2d-80937cd96ab3"
    ],
    "activity_id": null,
    "job_id": null,
    "job_run_id": null,
    "note_id": "76956e70-bbbc-4b33-be2a-34e56f62ca99",
    "user_id": "2e88cedb-3ef2-4ece-a12b-5d1a1f8bba67",
    "source": "web",
    "url": "https://finshots.in/archive/duct-tape-banana-sothebys-comedian-art-basel-miami-beach-maurizio-cattelan-art-money-laundering/",
    "source_data": {
      "top_image": "https://cdn.finshots.app/images/2024/11/Banana.png",
      "logo": "https://finshots.in/favicon.png",
      "published_date": "2024-11-25T01:30:00",
      "title": "A $6 million banana?!"
    },
    "summary": {
      "analysis": {
        "categories": [
          "Art Valuation",
          "Conceptual Art",
          "Money Laundering in Art",
          "Legislation and Regulation"
        ],
        "insights": [
          {
            "category": "Art Valuation",
            "points": [
              "The Mona Lisa is valued at close to a billion dollars.",
              "Art valuation is influenced by factors such as artist's fame, rarity, materials used, and the story behind the piece.",
              "The banana artwork, 'Comedian,' by Maurizio Cattelan, sold for $6.2 million despite its simple composition of a banana and duct tape.",
              "The banana's price increased 18 million percent from its original cost of 35 cents."
            ]
          },
          {
            "category": "Conceptual Art",
            "points": [
              "'Comedian' challenges traditional notions of art by representing an idea rather than a physical object.",
              "Justin Sun, founder of Tron, perceived the banana as a connection between art, meme culture, and the cryptocurrency community.",
              "Conceptual art emphasizes the idea behind the work rather than the work itself, as evidenced by Cattelan selling a certificate of authenticity and instructions on maintaining the banana."
            ]
          },
          {
            "category": "Money Laundering in Art",
            "points": [
              "The art market serves as a loophole for money laundering, as artworks can be priced subjectively without standardized valuation methods.",
              "Art transactions are often anonymous, allowing buyers and sellers to operate without revealing identities.",
              "Freeports act as tax-free storage facilities, enabling the laundering of money through inflated art sales while keeping the artwork in a static state."
            ]
          },
          {
            "category": "Legislation and Regulation",
            "points": [
              "Countries are implementing anti-money laundering laws to scrutinize suspicious activities in the art market.",
              "Mexico's early 2010s law requiring transparency about buyers caused a 70% drop in art sales due to cartel involvement.",
              "The U.S. Anti-Money Laundering Act of 2020 (AMLA 2020) mandates maintaining records and identifying true owners in antiquities businesses.",
              "The European Union's 2020 AML Directives require due diligence by art dealers and reporting of transactions exceeding €10,000."
            ]
          }
        ]
      }
    },
    "status": "success",
    "read": true,
    "created_at": "2024-11-28T08:58:11.832000",
    "updated_at": "2024-11-28T09:30:37.993000"
  });
  const { insightID } = useParams();
  const { darkTheme } = useContext(Context);
  const [path, setPath] = useState([]);
  const [showChat, setShowChat] = useState(false);
  const [allThreadData, setAllThreadData] = useState([]);
  const [actionState, setActionState] = useState(null);
  const [notesId, setNotesId] = useState(null);

  // useEffect(() => {
  //   const fetchInsightDetails = async () => {
  //     try {
  //       const response = await getThreadByChannelInsight("channel_id", insightID);
  //       if (response?.data) {
  //         setInsightDetails(response.data[0]);
  //         setPath(["Workspace Name", "Channel Name"]);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching insight details:", error);
  //     }
  //   };

  //   fetchInsightDetails();
  // }, [insightID]);

  const handleChatClick = async () => {
    setActionState(1);
    setShowChat(true);
    try {
      if (allThreadData.length === 0) {
        const response = await createChannelInsightThread("channel_id", insightID);
        if (response?.data) {
          const threadData = await getThreadByChannelInsight("channel_id", insightID);
          if (threadData?.data) {
            setAllThreadData(threadData.data);
          }
        }
      }
    } catch (error) {
      console.error("Error in handleChatClick:", error);
    }
  };

  const handleClickNotes = async () => {
    try {
      setActionState(0);
      if (!insightDetails?.note_id) {
        const response = await createNote("channel_id", insightID);
        if (response?.data?.note_id) {
          setNotesId(response.data.note_id);
          setInsightDetails({ ...insightDetails, note_id: response.data.note_id });
        }
      } else {
        setNotesId(insightDetails.note_id);
      }
    } catch (error) {
      console.error("Error updating insight details:", error);
    }
  };

  if (!insightDetails) {
    return <div>Loading Insight Details...</div>;
  }

  const { source_data, summary, url, created_at } = insightDetails;

  return (
    <div className="flex flex-col h-fit bg-[#fafbfa] oveflow-hidden">
      <SideNavigation />
      <Sidebar />
      <div className="opacity-[40%] pointer-events-none">
        <RightSidebar />
      </div>
      <div className={`flex-grow flex sm:ml-[230px] md:ml-[238px] lg:ml-[275px] xl:ml-[275px] 3xl:ml-[308px] border-t ${darkTheme ? "border-[#ffffff16]" : "border-[#00000016]"}`}>
        <div className="w-full p-4 shadow-md bg-white overflow-hidden custom-scrollbar hide-scrollbar scroll-container">
          <div className="flex flex-col items-center mb-6 py-4">
            {source_data?.top_image && (
              <img
                src={source_data.top_image}
                alt="Top"
                className="w-[140px] h-[120px] rounded-md shadow-md mb-4"
                onError={(e) => {
                  e.currentTarget.onerror = null;
                  e.currentTarget.src = "/logo.png";
                }}
              />
            )}
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-[24px] font-bold text-black hover:text-blue-500 hover:underline mb-2"
            >
              {source_data?.title}
            </a>
            <p className="text-[12px] text-[#1a1a1aa3]">
              <img
                src={source_data?.logo}
                alt="Source Logo"
                className="w-4 h-4 mr-2 inline"
                onError={(e) => {
                  e.currentTarget.onerror = null;
                  e.currentTarget.src = "/logo.png";
                }}
              />
              {source_data?.published_date
                ? format(new Date(source_data.published_date), "MMMM dd, yyyy")
                : format(new Date(created_at), "MMMM dd, yyyy")}
            </p>
            <div className="flex flex-row space-x-4 p-4">
              <button onClick={handleChatClick} className={`flex flex-row space-x-2 px-4 py-2 rounded-full border border-[#f15025] ${darkTheme
                ? 'text-white'
                : 'text-black'
                } transition-colors`}>
                <p className={`text-[14px] ${darkTheme ? "text-white" : "text-black"}`}>Chat</p>
                <IoChatbubblesSharp
                  className="text-[18px] mt-[2px] cursor-pointer text-green-700"
                />
              </button>
              <button onClick={handleChatClick} className={`flex flex-row space-x-2 px-4 py-2 rounded-full border border-[#f15025] ${darkTheme
                ? 'text-white'
                : 'text-black'
                } transition-colors`}>
                <p className={`text-[14px] ${darkTheme ? "text-white" : "text-black"}`}>Notes</p>

                <PiNotepadFill
                  onClick={handleClickNotes}
                  className="text-[18px] mt-[2px] cursor-pointer text-yellow-600"
                />
              </button>
            </div>

          </div>

          {/* Insight Summary */}
          <div className="px-4">
            {summary?.analysis?.categories?.map((category, index) => (
              <div key={index} className="mb-4">
                <h3 className="text-[20px] font-bold text-black capitalize mb-2">
                  {category}
                </h3>
                <ul className="list-disc pl-5 text-black">
                  {summary?.analysis?.insights
                    ?.find((insight) => insight.category === category)
                    ?.points.map((point, idx) => (
                      <li key={idx} className="text-[16px]">
                        {point}
                      </li>
                    ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScreenShare;