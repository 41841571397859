import React, { useContext, useEffect, useState, useRef } from "react";
import { Context } from "../../../Context/Context";
import { updateNotes, getNotesDetails } from "../../../services/notesService";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'

const NoteSkeleton = () => (
  <div className="animate-pulse">
    <div className="h-8 bg-gray-200 rounded w-1/4 mb-4"></div>
    <div className="space-y-3">
      <div className="h-4 bg-gray-200 rounded w-3/4"></div>
      <div className="h-4 bg-gray-200 rounded w-1/2"></div>
      <div className="h-4 bg-gray-200 rounded w-5/6"></div>
      <div className="h-4 bg-gray-200 rounded w-2/3"></div>
    </div>
  </div>
);

const Notes = () => {
  const { darkTheme, notesId, setNotesId, insightDetails } = useContext(Context);
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [lastSavedValue, setLastSavedValue] = useState("");

  const editorRef = useRef(null);
  const lastSavedValueRef = useRef(lastSavedValue);

  useEffect(() => {
    lastSavedValueRef.current = lastSavedValue;
  }, [lastSavedValue]);

  useEffect(() => {
    const loadNoteContent = async () => {
      if (!insightDetails.note_id) {
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        const noteDetails = await getNotesDetails(insightDetails.note_id);
        const content = noteDetails?.data?.content || "";
        setValue(content);
        setLastSavedValue(content);
      } catch (error) {
        console.error("Error loading note:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadNoteContent();

    return () => {
      setNotesId(null);
    };
  }, [notesId, setNotesId, insightDetails.note_id]);

  useEffect(() => {
    const pollInterval = 2000; // Save every 2 seconds
    const poller = setInterval(() => {
      saveChanges(value);
    }, pollInterval);

    return () => {
      clearInterval(poller);
    };
  }, [notesId, value]);

  const saveChanges = async (currentValue) => {
    if (!insightDetails.note_id || currentValue === lastSavedValueRef.current) {
      return; // Avoid saving if no changes
    }
    try {
      setIsSaving(true);
      const response = await updateNotes(insightDetails.note_id, currentValue);
      if (response?.data) {
        setLastSavedValue(currentValue);
      }
    } catch (error) {
      console.error("Error saving note:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ blockquote: true }, { code: true }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "align",
    "color",
    "background",
    "list",
    "bullet",
    "indent",
    "header",
    "blockquote",
    "code-block",
    "link",
    "image",
    "video",
  ];

  // Custom styles for placeholder
  const customStyles = `
    /* Placeholder Styles */
    .ql-editor.ql-blank::before {
      color: ${darkTheme ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)'} !important;
    }

    .ql-container {
          ${darkTheme
      ? 'border-color: #333 !important; color: #fff !important;'
      : 'border-color: #e0e0e0 !important; color: #000 !important;'}
    }
    .ql-toolbar {
      ${darkTheme
      ? 'border-color: #333 !important; color: #fff !important;'
      : 'border-color: #e0e0e0 !important; color: #000 !important;'}
    }

    /* Toolbar Button Styles */
    .ql-toolbar .ql-picker,
    .ql-toolbar .ql-picker-label,
    .ql-toolbar button {
      ${darkTheme
      ? 'color: #c0c0c0 !important; fill: #c0c0c0 !important;'
      : 'color: #555 !important; fill: #555 !important;'}
    }

    /* Toolbar Hover and Active States */
    .ql-toolbar .ql-picker:hover,
    .ql-toolbar button:hover,
    .ql-toolbar .ql-picker-label:hover,
    .ql-toolbar button.ql-active,
    .ql-toolbar .ql-picker-label.ql-active {
      ${darkTheme
      ? 'color: #fff !important; background-color: #404040 !important;'
      : 'color: #000 !important; background-color: #e0e0e0 !important;'}
    }

    .ql-picker-options .ql-picker-item:hover {
      ${darkTheme
      ? 'background-color: #404040 !important;'
      : 'background-color: #f1f3f4 !important;'}
    }
  `;

  if (isLoading) {
    return (
      <div
        className={`h-full w-full mx-auto transition-all duration-200 border-l ${darkTheme ? "bg-[#181818] border-[#ffffff16]" : "bg-[#fafbfa] text-gray-900"
          }`}
      >
        <div
          className={`flex justify-between border-b p-[1px] ${darkTheme ? "border-[#ffffff16]" : "border-[#00000016]"
            }`}
        >
          <div className="flex p-2 mx-2 min-h-[40px]">Notes</div>
        </div>
        <div className="p-6">
          <NoteSkeleton />
        </div>
      </div>
    );
  }

  return (
    <div
      className={`sm:min-h-screen mx-auto transition-all duration-200 ${darkTheme ? "bg-[#181818] border-[#ffffff16]" : "bg-[#fafbfa] text-gray-900"
        }`}
    >
      <style>{customStyles}</style>
      <div
        className={`flex justify-between border-b p-[1px] ${darkTheme ? "border-[#ffffff16]" : "border-[#00000016]"
          }`}
      >
        <div className="flex p-2 mx-2 min-h-[40px]">Notes</div>
        <div>
          {isSaving && (
            <div className="flex justify-center pr-16 min-h-[40px] items-center">
              Saving...
            </div>
          )}
        </div>
      </div>
      <div className="pl-0 pb-4 pr-[50px] 3xl:pr-[70px] h-screen overflow-hidden">
        <div className="h-full">
          <ReactQuill
            theme="snow"
            value={value}
            onChange={setValue}
            modules={modules}
            formats={formats}
            className={`h-full !border-none ${darkTheme ? "!text-white" : "!text-black"
              }`}
            placeholder="Write your note here..."
          />

        </div>
      </div>
    </div>
  );
};

export default Notes;